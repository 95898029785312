<template>
  <main role="main">
    <slot />
  </main>
</template>

<script>
export default {
  name: 'Main',
  props: {
  }
}
</script>

<style scoped lang="scss">
</style>
