<template>
  <div class="news">
    <div class="wrap">
      <section>
        <h3 class="news-heading">News</h3>
        <p class="news-button">
          <Button
            :path="{name: 'NewsList'}"
            text="一覧を見る"
          />
        </p>
        <ul class="news-list">
          <li v-for="newsIndex in 5" :key="newsIndex">
            <NewsDate :newsDate="newsList[newsIndex-1].dateTime" />
            <NewsCategory :category-id="newsList[newsIndex-1].category.id" isLink />
            <p class="news-title">
              <router-link
                :to= "{
                  name: 'NewsPost',
                  params: {
                    categoryId: newsList[newsIndex-1].category.id,
                    postId: newsList[newsIndex-1].id,
                  },
                }"
              >
                {{ newsList[newsIndex-1].title }}
              </router-link>
            </p>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
  import NewsPosts from '@/jsons/news.json'

  import NewsCategory from '@/components/common/NewsCategory.vue'
  import NewsDate from '@/components/common/NewsDate.vue'
  import Button from '@/components/common/LinkButton.vue'

  export default {
    name: 'HomeNews',
    components: {
      NewsCategory,
      NewsDate,
      Button,
    },
    data () {
      return {
        newsList: NewsPosts.contents,
      }
    },
    created () {
    },
    methods: {
    },
    mounted: function() {
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .news {
    display: block;
    padding: 35px 0px;

    @media #{$device-l} {
      padding: 75px 0 55px;
      font-size: 1.4rem;
    }

    .wrap {
      @media #{$device-s} {
        padding: 0 15px;
      }
    }

    section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      @media #{$device-l} {
        display: grid;
        grid-template-columns: 120px 1fr;
        grid-template-rows   :  38px 1fr;
        grid-template-areas  :
          "heading list"
          "button  list";
        grid-column-gap: 70px;
        grid-row-gap   : 10px;
      }
    }

    &-heading,
    &-button,
    &-list {
      align-self: flex-start;
    }

    &-heading {
      font-size: 2.8rem;
      color: map-get($color-brand, "accent");
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.05em;

      @media #{$device-l} {
        grid-area: heading;
        font-size: 3.8rem;
      }
    }

    &-button {
      @media #{$device-l} {
        grid-area: button;
      }
    }

    &-list {
      margin-top: 30px;
      width: 100%;

      @media #{$device-l} {
        grid-area: list;
        margin-top: 5px;
      }

      >li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 10px;

        @media #{$device-l} {
          flex-wrap: nowrap;
          align-items: flex-start;
          margin-top: 20px;
        }

        &:first-child {
          margin-top: 0
        }
      }
    }

    &-date {
      letter-spacing: 0.05em;

      @media #{$device-l} {
        margin-top: 8px;
        margin-right: 20px;
      }
    }

    &-category {
      justify-content: flex-end;
      margin-top: 2px;
      margin-left: 10px;
      text-align: right;

      @media #{$device-l} {
        order: -1;
        width: 115px;
        margin-left: 0;
      }

      &::after {
        @media #{$device-l} {
          content: "";
          display: block;
          width: 1px;
          height: 14px;
          margin: 0 10px;
          background-color: map-get($color-text, "base");
          transform-origin: center center;
          transform: skew(-25deg);

          @media #{$device-dark} {
            background-color: #fff;
          }
        }
      }
    }

    &-title {
      width: 100%;
      margin-top: 8px;
      line-height: 1.5;

      @media #{$device-l} {
        width: auto;
        margin-top: 4px;
      }

      >a {
        display: inline-block;
        overflow: hidden;
        padding: 5px 0;
        color: inherit;
        text-decoration: none;

        @media #{$device-l} {
          padding: 0;
          transition-duration: $duration;

          &:hover {
            opacity: $alpha;
          }
        }
      }
    }
  }
</style>
