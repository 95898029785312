<template>
  <div class="mail">
    <div class="wrap">
      <dl class="mail-address">
        <dt>メールアドレス</dt>
        <dd>
          <p class="mail-address-button">
            <a
              href="javascript: void(0)"
              @click="setClipBoardAlert(SiteInfo.mail, 'メールアドレス')"
            >
              <MailIcon />info@meta-mo.co.jp
            </a>
          </p>
          <p class="mail-address-caption">
            <small>メールアドレスをクリックでコピーできます</small>
          </p>
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'

  import MailIcon from '@/assets/images/contact/contact_mail.svg'

  export default {
    name: 'ContactMail',
    components: {
      MailIcon,
    },
    data () {
      return {
        SiteInfo: SiteInfo,
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  $color: #707070;

  .mail {
    padding: 60px 0 50px;
    color: $color;

    @media #{$device-l} {
      padding-top: 100px;
    }

    @media #{$device-dark} {
      color: inherit;
    }

    &-address {
      text-align: center;

      @media #{$device-l} {
      }

      >dt {
        margin-bottom: 15px;
        font-size: 1.6;

        @media #{$device-l} {
          margin-bottom: 40px;
          font-size: 2.0rem;
        }
      }

      >dd {

        @media #{$device-l} {
        }
      }

      &-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 -30px;
        line-height: 1.4;
        font-size: 2.8rem;

        @media #{$device-l} {
          font-size: 4.4rem;
        }

        a {
          display: block;
          overflow: hidden;
          padding: 5px;
          color: inherit;
          text-decoration: none;
          white-space: nowrap;

          @media #{$device-l} {
            transition-duration: $duration;

            &:hover {
              opacity: $alpha;
            }
          }
        }

        svg {
          width: 18px;
          margin-right: 12px;

          @media #{$device-l} {
            width: 30px;
            margin-right: 20px;
          }

          path {
            fill: $color;

            @media #{$device-dark} {
              fill: map-get($color-dark, "text");
            }
          }
        }
      }

      &-caption {
        margin-top: 10px;
        line-height: 1.5;
        font-size: 1.2rem;
        color: #b9b9b9;

        @media #{$device-l} {
          margin-top: 20px;
          font-size: 1.4rem;
        }

        @media #{$device-dark} {
          color: inherit;
        }
      }
    }
  }
</style>
