<template>
  <div
    class="loading"
    :class="{'is-done': done}"
  >
    <div class="loading-icon"></div>
  </div>
</template>

<script>
  export default {
    name: 'Loading',
    props: {
      done: {
        type: Boolean,
        default: false
      }
    },
    components: {
    },
    data () {
      return {
      }
    },
    mounted () {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  $size: 58px;
  $color: map-get($color-brand, "main");
  $minSize: (8px / $size);
  $animateDuray: 0.5s;

  .loading {
    position: fixed;
    z-index: 100;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    overflow: hidden;
    margin: auto;
    background-color: #fff;
    transition-duration: $animateDuray;

    @media #{$device-l} {
      top: 65px;
    }

    @media #{$device-dark} {
      background-color: map-get($color-dark, "main");
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      width: 100%;
      height: 100vh;
      background-color: $color;
      transition-duration: $animateDuray;
    }

    &-icon {
      position: absolute;
      z-index: 90;
      top: 0;
      right: 0;
      bottom: 60px;
      left: 0;
      display: block;
      overflow: hidden;
      width: $size;
      height: $size;
      margin: auto;
      border-radius: 50%;
      transition-duration: $animateDuray;

      @media #{$device-l} {
        bottom: 0;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba($color, 0.5);
        border-radius: 50%;
        transform-origin: center center;
        animation-duration: 2.0s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }

      &::before {
        transform: scale($minSize);
        animation-name: loadingZoomIn;
      }

      &::after {
        transform: scale(1.0);
        animation-name: loadingZoomOut
      }
    }

    &.is-done {
      top: 0 !important;
      animation: loadingFinished $animateDuray ease 1.5s 1 normal forwards;

      @media #{$device-l} {
      }

      &::before {
        top: 0;
      }

      .loading {
        &-icon {
          top: 60px;

          @media #{$device-l} {
            top: 65px;
          }
        }
      }
    }
  }

  @keyframes loadingZoomIn {
    0%, 100% {
      transform: scale($minSize);
    }
    50% {
      transform: scale(1.0);
    }
  }

  @keyframes loadingZoomOut {
    0%, 100% {
      transform: scale(1.0);
    }
    50% {
      transform: scale($minSize);
    }
  }

  @keyframes loadingFinished {
    0% {
      bottom: 0;
    }
    100% {
      bottom: 100%;
    }
  }
</style>
