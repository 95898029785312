<template>
  <h2
    v-cloak
    class="hero"
    :class="[setSizeClass]"
  >
    <slot />
  </h2>
</template>

<script>
  export default {
    name: 'Hero',
    components: {
    },
    props: {
      sizeClass: String,
    },
    data () {
      return {
      }
    },
    computed: {
      setSizeClass () {
        if(this.sizeClass) {
          return ['is-' + this.sizeClass]
        } else {
          return []
        }
      },
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  $color: #454545;

  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    max-height: 260px;
    margin-top: 60px;
    color: $color;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @media #{$device-l} {
      max-height: 400px - 65px;
      margin-top: 65px;
    }

    &.is-medium {
      justify-content: flex-start;
      max-height: 330px;

      @media #{$device-l} {
        max-height: 600px;
      }

      &::before {
        flex: 0 0 auto;
        content: "";
        display: block;
        width: 1px;
        height: 20vh;
        max-height: 80px;

        @media #{$device-s} {
          height: 30vh;
        }

        @media #{$device-l} {
          height: 26%;
          max-height: 26%;
        }
      }
    }

    .heading {
      border-color: map-get($color-text, "base");
      margin-top: 0;
      margin-bottom: 0;

      @media #{$device-m} {
        width: 50%;
      }

      ::v-deep >small {
        border-color: $color;
      }
    }
  }
</style>
