<template>
  <div class="l-container">
    <Header />
    <Main>
      <section>
        <h2
          class="hero"
        >
          <span>
            <img :src="require('@/assets/images/home/hero_typo_only.png')" alt="Link Design to Data" />
          </span>
          <video
            class="hero-bg sp-tb"
            playsinline
            autoplay
            muted
            loop
          >
            <source
              v-cloak
              :src="require('@/assets/images/home/hero_mv_sp.mp4')"
              type="video/mp4;"
            >
            <source
              v-cloak
              :src="require('@/assets/images/home/hero_mv_sp.webm')"
              type="video/webm;"
            >
            <source
              v-cloak
              :src="require('@/assets/images/home/hero_mv_sp.ogg')"
              type="video/ogg;"
            >
          </video>
          <video
            class="hero-bg pc"
            playsinline
            autoplay
            muted
            loop
          >
            <source
              v-cloak
              :src="require('@/assets/images/home/hero_mv.mp4')"
              type="video/mp4;"
            >
            <source
              v-cloak
              :src="require('@/assets/images/home/hero_mv.webm')"
              type="video/webm;"
            >
            <source
              v-cloak
              :src="require('@/assets/images/home/hero_mv.ogg')"
              type="video/ogg;"
            >
          </video>
        </h2>
        <News />
        <Letter />
        <About />
        <Product />
        <Contact />
      </section>
    </Main>
    <Footer />
  </div>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'

  import Header from '@/components/essentials/Header.vue'
  import Main from '@/components/essentials/Main.vue'
  import Footer from '@/components/essentials/Footer.vue'

  import News from '@/components/home/HomeNews.vue'
  import Letter from '@/components/home/HomeLetter.vue'
  import About from '@/components/home/HomeAbout.vue'
  import Product from '@/components/home/HomeProduct.vue'
  import Contact from '@/components/home/HomeContact.vue'

  export default {
    name: 'Metamo',
    components: {
      Header,
      Main,
      Footer,
      News,
      Letter,
      About,
      Product,
      Contact,
    },
    data () {
      return {
        SiteInfo: SiteInfo,
      }
    },
    created () {
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    head: {
      meta () {
        return this.defaultHead('meta', [])
      },
      link () {
        return this.defaultHead('link', [])
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .hero {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    max-height: 1080px;
    text-shadow: 5px 5px 15px rgba(#000, 0.65);
    background-color: map-get($color-brand, "sub");
    background-image: url('~@/assets/images/home/hero_mv_sp.jpg');
    background-position: center center;
    background-size: cover;

    @media #{$device-l} {
      background-image: url('~@/assets/images/home/hero_mv.jpg');
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      width: 1800px;
      height: 1800px;
      background: inherit;
      background-size: contain;
      transform: translate(-50%, -50%);

      @media #{$device-l} {
        width: 1920px;
        height: 1080px;
      }
    }

    &::after {
      @media #{$device-dark} {
        content: "";
        position: absolute;
        z-index: 30;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(#28253C, 0.25);
      }
    }

    &-bg {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    >span {
      position: relative;
      z-index: 40;
      display: block;
      width: calc(100% - 60px);
      min-width: 280px;
      max-width: 400px;
      background-size: 0 0;
      background-repeat: no-repeat;
      background-position: left -100% top 100%;

      @media #{$device-l} {
        max-width: 1432px * 0.5;
      }

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        display: block;
        width: 1772px / 1432px * 100%;
        padding-top: 1092px / 1772px * 100%;
        background-image: url('~@/assets/images/home/hero_typo_bg.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        transform: translate(-50%, -50%);
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }
</style>
