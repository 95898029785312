import 'mdn-polyfills/NodeList.prototype.forEach'
import 'mdn-polyfills/String.prototype.padStart'
import 'mdn-polyfills/Object.keys'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mixin from './mixin'

import axios from 'axios'

import vueSmoothScroll from 'vue-smooth-scroll'
import vueClipboard from 'vue-clipboard2'
import sanitizeHTML from 'sanitize-html'

import 'reset-css'

Vue.mixin(mixin)

Vue.config.productionTip = false

require('@/styles/styles.scss')

Vue.prototype.$axios = axios
Vue.prototype.$sanitize = sanitizeHTML

Vue.use(vueSmoothScroll)
Vue.use(vueClipboard)

new Vue({
  router,
  store,
  mixin,
  render: h => h(App),
}).$mount('#app')
