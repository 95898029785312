<template>
  <div class="about">
    <section>
      <h3 class="about-heading">
        <Heading
          section-class="content"
          jp-text = "メタモについて"
          en-text = "About"
        />
      </h3>
      <p class="about-text">
        会社メッセージやメタモの過去の歩み、取締役の人となりを紹介します。メタモについてもっと知っていただくきっかけに。
      </p>
      <p class="about-button">
        <Button
          :path="{name: 'About'}"
          text="メタモについて知る"
        />
      </p>
      <div class="about-image is-light"
        :style="{'background-image': 'url('+require('@/assets/images/home/home_about.jpg')+')'}"
      ></div>
      <div class="about-image is-dark"
        :style="{'background-image': 'url('+require('@/assets/images/home/home_about_dark.jpg')+')'}"
      ></div>
    </section>
  </div>
</template>

<script>
  import Heading from '@/components/common/PageHeading.vue'
  import Button from '@/components/common/LinkButton.vue'

  export default {
    name: 'HomeAbout',
    components: {
      Heading,
      Button,
    },
    data () {
      return {
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .about {
    position: relative;
    z-index: 10;
    padding: 0 20px 30px;

    @media #{$device-l} {
      max-width: 645px + 50px + 460px;
      padding: 0 $padding;
      padding-right: 60px - $padding + 50px;
      margin: 125px auto 100px + 125px;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      right: 60px - $padding;
      bottom: calc(165px - 65%);
      left: $padding + 420px - 120px;
      display: block;
      height: 100%;
      margin: auto;
      background-color: map-get($color-brand, "base");

      @media #{$device-s} {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      @media #{$device-l} {
      }

      @media #{$device-dark} {
        background-color: rgba(#fff, 0.1);
      }
    }

    >section {
      display: grid;
      grid-template:
        "image heading" 1fr
        "image text   " auto
        "image button " 55px /
        40% calc(60% - 40px);
      column-gap: 40px;
      row-gap: 10px;

      @media #{$device-s} {
        display: flex;
        flex-direction: column;
      }

      @media #{$device-l} {
        grid-template:
          "image heading" 1fr
          "image text   " auto
          "image button " 55px /
          55% 40%;
        column-gap: 50px;
        row-gap: 20px;
      }
    }

    &-text,
    &-button,
    &-image {
      align-self: flex-end;

      @media #{$device-s} {
      }
    }

    &-heading {
      grid-area: heading;
      display: flex;
      align-self: flex-end;

      @media #{$device-s} {
        align-self: center;
        margin: 20px 0 15px;
      }

      /deep/ .heading {
        align-self: flex-end;
        padding: 0;
        margin: 0;
        text-align: left;

        @media #{$device-s} {
        }
      }
    }

    &-text {
      grid-area: text;
      line-height: 2.0;

      @media #{$device-s} {
        margin-bottom: 20px;
      }
    }

    &-button {
      grid-area: button;
      justify-self: flex-end;
      margin-top: 0;

      @media #{$device-s} {
        display: flex;
        justify-content: center;
        margin-top: 25px;
      }
    }

    &-image {
      grid-area: image;
      width: 100%;
      margin: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left 15% center;

      @media #{$device-s} {
        order: -1;
        position: relative;
        display: block;
        width: calc(100% + 40px);
        margin: 0 -20px;
      }

      &::before {
        content: "";
        display: block;
        padding-top: 401px / 644px * 100%;

        @media #{$device-s} {
          padding-top: 280px / 414px * 100%;
        }
      }
    }
  }
</style>
