var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news-pickup-wrapper"},[_c('div',{staticClass:"news-pickup"},[_c('h3',{staticClass:"news-pickup-heading"},[_vm._v("Pick up")]),_c('div',{staticClass:"news-pickup-information"},[_c('NewsDate',{attrs:{"newsDate":_vm.post.dateTime}}),_c('NewsCategory',{attrs:{"category-id":_vm.post.category.id}})],1),_c('router-link',{staticClass:"news-pickup-post",attrs:{"to":{
        name: 'NewsPost',
        params: {
          categoryId: _vm.post.category.id,
          postId: _vm.post.id,
        },
      }}},[_c('h4',{staticClass:"news-pickup-title",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.post.title))}}),_c('p',{staticClass:"news-pickup-text",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.post.body))}})]),_c('router-link',{staticClass:"news-pickup-image",attrs:{"to":{
        name: 'NewsPost',
        params: {
          categoryId: _vm.post.category.id,
          postId: _vm.post.id,
        },
      }}},[(_vm.post.thumbnail)?_c('img',{attrs:{"src":_vm.post.thumbnail.url,"alt":""}}):[_c('img',{staticClass:"is-light",attrs:{"src":require('@/assets/images/news/thum_light.jpg'),"alt":""}}),_c('img',{staticClass:"is-dark",attrs:{"src":require('@/assets/images/news/thum_dark.jpg'),"alt":""}})]],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }