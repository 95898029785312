<template>
  <div
    class="to-chrome pc"
    :class="{'is-out-of-scope': flg}"
  >
    <div class="wrap">
      <h2 class="to-chrome-heading">サポート対象外のブラウザです</h2>
      <p class="to-chrome-text">
        現在お使いの「Internet Explorer」はすでにMicrosoftによる公式サポートが終了しています。<br>
        正しい表示でご覧いただくためには、「Google Chrome」のダウンロードを推奨いたします。
      </p>
      <p class="to-chrome-button">
        <Button
          color-class="accent"
          text="Google Chrome 公式サイトへ"
          path="//www.google.com/intl/ja_jp/chrome/"
        />
      </p>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/common/LinkButton.vue'

  export default {
    name: 'ToChrome',
    components: {
      Button,
    },
    data () {
      return {
        flg: false,
      }
    },
    created () {
      const ua = navigator.userAgent;
      if ( ua.indexOf('Trident') !== -1 ) {
        this.flg = true
      }
      else {
        this.flg = false
      }
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .to-chrome {
    display: none;
    position: fixed;
    z-index: 90;
    top: 74px;
    left: 0;
    width: 100%;
    padding: 45px 0 40px;
    text-align: center;
    color: #fff;
    background-color: rgba(#333, 0.8);

    &.is-out-of-scope {
      display: block;
    }

    &-heading {
      margin: 0 auto 20px;
      line-height: 1.0;
      font-size: 2.4rem;
    }

    &-text {
      margin: 20px 0;
      line-height: 1.7;
    }

    &-button {
      width: auto;
      max-width: 260px;
      margin: 0 auto;
      background-color: #fff;

      @media #{$device-dark} {
        background-color: transparent;
      }

      ::v-deep {
        .button {
          z-index: 10;

          @media #{$device-dark} {
            &:hover {
              border-color: #fff;
              color: #fff;

              &.is-blank {
                svg {
                  &, path {
                    stroke: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
