import Vue from 'vue'
import Router from 'vue-router'
import VueHead from 'vue-head'

import Metamo from './views/Metamo.vue'
import About from './views/About.vue'
import Company from './views/Company.vue'
import Founder from './views/Founder.vue'
import Contact from './views/Contact.vue'
import NewsList from './views/NewsList.vue'
import NewsPost from './views/NewsPost.vue'
import PrivacyNotice from './views/PrivacyNotice.vue'

Vue.use(Router)

// 初期のメタの削除
const initialMetas = document.head.querySelectorAll('meta')
initialMetas.forEach((meta, index) => {
  if (index < 3) return
  meta.parentElement.removeChild(meta)
})

Vue.use(VueHead, {
  separator: '|',
  complement: 'メタモ株式会社 - Metamo Inc.',
})

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Metamo",
      component: Metamo,
    },
    {
      path: "/about",
      name: "About",
      component: About,
    },
    {
      path: "/company",
      name: "Company",
      component: Company,
    },
    {
      path: "/founder",
      name: "Founder",
      component: Founder,
    },
    {
      path: "/news",
      redirect: { name: 'NewsList' },
    },
    {
      path: "/news-list",
      redirect: { name: 'NewsList' },
    },
    {
      path: "/news-list/all/1",
      redirect: { name: 'NewsList' },
    },
    {
      path: "/news-list/:category/1",
      redirect: { name: 'NewsCategoryList' },
    },
    {
      path: "/news-list/all",
      name: "NewsList",
      component: NewsList,
      children: [
        {
          path: ":page",
          name: "NewsListPage",
          component: NewsList,
        },
      ],
    },
    {
      path: "/news-list/:category",
      name: "NewsCategoryList",
      component: NewsList,
      children: [
        {
          path: ":page",
          name: "NewsCategoryListPage",
          component: NewsList,
        },
      ]
    },
    {
      path: "/news/:postId",
      name: "NewsPost",
      component: NewsPost,
    },
    {
      path: "/contact",
      name: "Contact",
      component: Contact,
    },
    {
      path: "/privacy/notice",
      name: "PrivacyNotice",
      component: PrivacyNotice,
    },
    {
      path: "*",
      redirect: {
        name: "Metamo",
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            offset: { x: 0, y: 60 }
          })
        }, 200)
      })
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
