<template>
  <div class="letter">
    <div class="wrap">
      <section>
        <h3 class="letter-heading">Metamo Letter</h3>
        <div class="letter-container">
          <div class="letter-image"
            :style="{'background-image': 'url('+require('@/assets/images/home/home_letter.png')+')'}"
          ></div>
          <div class="letter-content">
            <section>
              <h4 class="letter-title">代表の考えや想いをnoteで伝えます</h4>
              <!-- <p class="letter-date">
                <time datetime="2019-06-25">2019.06.25</time>
              </p> -->
              <div class="letter-text">
                <p>
                  コーポレートサイトやTwitterなどでは伝わりづらい、メタモの代表である佐藤由太の日頃の考えや想いを綴っています。<br>
                  メタモに関する事業展望・日々のちょっとした出来事だけでなく、スタートアップやテック業界について感じ・考え・思うことを発信するのが「Metamo Letter」です。
                </p>
              </div>
              <p class="letter-button">
                <Button
                  :path="noteURL"
                  text="noteで読む"
                />
              </p>
            </section>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/common/LinkButton.vue'

  export default {
    name: 'HomeLetter',
    components: {
      Button,
    },
    data () {
      return {
        noteDatas: '',
        noteURL: 'https://note.com/disnism'
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .letter {
    padding: 115px 0 125px;

    @media #{$device-s} {
      padding: 35px 0 100px;
    }

    .wrap {

      @media #{$device-s} {
      }

      @media #{$device-l} {
        width: calc(100% - 70px);
        min-width: 940px;
        max-width: $pc-max-width;
      }
    }

    &-heading {
      margin-bottom: 30px;
      text-align: left;
      font-size: 5.1rem;

      @media #{$device-s} {
        margin-bottom: 10px;
        text-align: center;
        font-size: 3.8rem;
        font-weight: bold;
      }
    }

    &-container {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-start;

      @media #{$device-s} {
        display: block;
      }
    }

    &-image {
      flex: 0 0 auto;
      width: 40%;
      background-repeat: no-repeat;
      background-size: contain;
      user-select: none;

      @media #{$device-s} {
        width: 100%;
        max-width: 450px;
        margin: 10px auto 25px;
      }

      @media #{$device-l} {
        width: calc(52.25% - 20px);
        margin: -100px 0 0;
      }

      &::before {
        content: "";
        display: block;
        padding-top: 296px / 380px * 100%;
      }
    }

    &-content {
      flex: 0 0 auto;
      width: calc(60% - 20px);
      margin: 0;

      @media #{$device-s} {
        width: auto;
        margin-top: 25px;
      }

      @media #{$device-l} {
        width: calc(47.75% - 20px);
      }

      >section {
        display: flex;
        flex-direction: column;
      }
    }

    &-title {
      margin: 20px 0;
      line-height: 1.5;
      font-size: 2.4rem;
      font-weight: bold;
      color: map-get($color-brand, "accent");

      @media #{$device-s} {
        margin: 10px 0;
        font-size: 2.0rem;
      }
    }

    &-date {
      order: -1;
      font-size: 1.1rem;

      @media #{$device-s} {
        font-size: 1.2rem;
      }
    }

    &-text {
      line-height: 2.0;
      font-size: 1.6rem;

      @media #{$device-s} {
        line-height: 2.5;
        font-size: 1.4rem;
      }
    }

    &-button {
      align-self: flex-end;
      margin-top: 10px;
    }
  }
</style>
