<template>
  <div class="side-navigation-wrapper">
    <div
      class="side-navigation"
      :class="{'is-fixed': this.fixedClass}"
    >
      <ul class="side-navigation-list">
        <li
          v-for="(nav, index) in navigation"
          :key="index"
        >
          <a
            :href="'#'+nav.path"
            :class="{'is-active': (nav.path === activePath)}"
            v-smooth-scroll="{ duration: duration, offset: sideOffset }"
          >{{nav.text}}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'sideNavigation',
    components: {
    },
    props: {
      navigation: Array,
      fixedPositionAjustment: Number
    },
    data () {
      return {
        activePath: '',
        fixedClass: false,
        duration: 500,
        offset: { pc: -135 ,sp: -60 },
        sideWidth: 0,
      }
    },
    created () {
    },
    mounted () {
      window.addEventListener('resize', this.setSideWidth)
      this.setSideWidth()
      window.addEventListener('scroll', this.sideNavScroll);
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.setSideWidth)
      window.removeEventListener('scroll', this.sideNavScroll);
    },
    computed: {
      sideOffset () {
        return (this.sideWidth >= 768) ? this.offset.pc : this.offset.sp
      },
    },
    methods: {
      sideNavScroll () {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const windowHeight = window.innerHeight;

        // コンテンツの高さを計算
        const navigationContents = this.navigation.map(value => {
          const element = document.querySelector('#' + value.path);
          return {
            path: value.path,
            offsetTop: element.offsetTop
          };
        });

        // コンテンツ全体の開始点よりスクロールしている時、ナビゲーションを固定
        this.fixedClass = (scrollTop + this.fixedPositionAjustment > navigationContents[0].offsetTop);

        // コンテンツの開始点-画面の半分の高さよりスクロール位置が大きい時、そのコンテンツをアクティブにする
        navigationContents.forEach(item => {
          if (scrollTop > item.offsetTop - windowHeight / 2) this.activePath = item.path;
        });
      },
      setSideWidth () {
        this.sideWidth = window.innerWidth
      },
    }
  }
</script>
<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .side-navigation {
    $position: ("sp": 0, "pc": 74px);

    &-wrapper {
      position: relative;
      overflow: hidden;
      height: 60px;
      padding-top: map-get($position, "sp");
      margin: #{30px - map-get($position, "sp")} 0 30px;

      @media #{$device-l} {
        overflow: visible;
        margin-top: 75px;
        margin-bottom: 50px;
      }
    }

    box-sizing: border-box;
    position: absolute;
    z-index: 70;
    top: map-get($position, "sp");
    left: 0;
    display: flex;
    overflow-x: auto;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: inherit;
    padding-right: 10px;
    background-color: #fff;

    @media #{$device-l} {
      border: 1px solid #ededed;
      border-right: none;
      border-left: none;
      padding-right: 0;
    }

    @media #{$device-dark} {
      border-color: #2f2c44;
      background-color: map-get($color-dark, "main");
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 10px;
      height: inherit;
      background-color: inherit;
      box-shadow: 0px -2px 3px rgba(#000, 0.3);

      @media #{$device-l} {
        content: none;
      }
    }

    &.is-fixed {
      position: fixed;
      padding-right: 60px;

      @media #{$device-l} {
        top: map-get($position, "pc");
        padding-right: 0;
      }

      &::after {
        width: 60px;
      }
    }

    &-list {
      display: flex;
      justify-content: flex-start;
      overflow-x: auto;
      width: auto;
      padding: 20px 0;

      @media #{$device-l} {
        box-sizing: border-box;
        width: 100%;
        max-width: $pc-min-width;
        padding: 20px $padding;
        margin: 0 auto;
      }

      >li {
        margin-left: 5px;

        @media #{$device-l} {
          margin-left: 20px;
        }

        &:first-child {
          padding-left: 30px;
          margin-left: 0;

          @media #{$device-l} {
            padding-left: 0;
          }
        }

        &:last-child {
          padding-right: 30px;

          @media #{$device-l} {
            padding-right: 0;
          }
        }
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 18px;
      padding: 0 10px;
      border-left: 3px solid #ededed;
      font-size: 1.2rem;
      color: #b9b9b9;
      font-weight: bold;
      text-decoration: none;
      white-space: nowrap;
      transition-duration: $duration;

      @media #{$device-l} {
        justify-content: flex-start;
        padding-left: 7px;
      }

      @media #{$device-dark} {
        border-color: map-get($color-dark, "gray");
        color: map-get($color-dark, "gray");
      }

      &.is-active {
        border-left-color: map-get($color-brand, "purple");
        color: #707070;

        @media #{$device-dark} {
          color: map-get($color-dark, "text");
        }
      }
    }
  }
</style>
