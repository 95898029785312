<template>
  <router-link
    v-if="aroundClass === 'prev' || aroundClass === 'next'"
    :to="path"
    class="pager-button"
    :class="[setAroundClass(), setDisableClass()]"
  >
    {{ setText() }}のページへ
  </router-link>

  <router-link
    v-else-if="path"
    :to="path"
    class="pager-button"
    :class="[setActiveClass()]"
  >
    {{ number }}
  </router-link>

  <p
    v-else
    class="pager-button"
  >
    {{ setText() }}
  </p>
</template>

<script>
  export default {
    name: 'PagerButton',
    props: {
      path: {
        type: [String, Object],
        default: () => {}
      },
      number: {
        type: Number,
        default: 0,
      },
      aroundClass: String,
      isDisable: Boolean,
      isActive: Boolean,
    },
    components: {
    },
    data () {
      return {
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
      setText () {
        if(this.aroundClass === 'next') {
          return "次"
        } else if(this.aroundClass === 'prev') {
          return "前"
        } else {
          return "･･･"
        }
      },
      setAroundClass () {
        if(this.aroundClass) {
          return ['is-' + this.aroundClass]
        } else {
          return []
        }
      },
      setDisableClass () {
        if(this.isDisable) {
          return ['is-disable']
        } else {
          return []
        }
      },
      setActiveClass () {
        if(this.isActive) {
          return ['is-active']
        } else {
          return []
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .pager-button {
    $bar: 15px;
    $color: #707070;

    flex: 0 0 auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 43px;
    color: $color;
    text-decoration: none;

    @media #{$device-s} {
      width: 44px;
      height: 50px;
    }

    &[href] {

      @media #{$device-l} {
        transition-duration: $duration;

        &:hover {
          background-color: #ededed;
        }
      }
    }

    @media #{$device-dark} {
      border-color: #fff;
      color: #fff;
    }

    &.is-prev,
    &.is-next {
      position: relative;
      display: block;
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
      }

      &::before {
        right: 0;
        left: 0;
        display: block;
        width: $bar;
        height: 1px;
        margin: auto;
        background-color: $color;

        @media #{$device-dark} {
          background-color: #fff;
        }
      }

      &::after {
        $size: 6px;

        width: $size;
        height: $size;
        margin: auto 0;
        border: 1px solid $color;
        border-left: none;
        border-bottom: none;
        transform-origin: center center;

        @media #{$device-dark} {
          border-color: #fff;
        }
      }
    }

    &.is-prev {

      &::after {
        left: (36px - $bar) / 2;
        transform: rotate(225deg);

        @media #{$device-s} {
          left: (44px - $bar) / 2;
        }
      }
    }

    &.is-next {

      &::after {
        right: (36px - $bar) / 2;
        transform: rotate(45deg);

        @media #{$device-s} {
          right: (44px - $bar) / 2;
        }
      }
    }

    &.is-disable {
      opacity: 0.5;
      user-select: none;
      pointer-events: none;
    }

    &.is-active {
      background-color: #ededed;

      @media #{$device-dark} {
        background-color: rgba(#707070, 0.5);
      }
    }
  }

  p.pager-button {
    @media #{$device-s} {
      display: none;
    }
  }
</style>
