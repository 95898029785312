<template>
  <div
    class="modal-wrapper"
    :class="{'is-active': isActive}"
  >
    <div class="modal">
      <div class="modal-header">
        <button
          type="button"
          class="modal-close"
          v-on:click="[isActive = false, setBodyScroll(isActive)]"
        >
          <span class="modal-close-icon"></span>
          <span class="modal-close-text">Close</span>
        </button>
      </div>
      <div class="modal-body">
        <img :src="imgSrc" alt="" />
      </div>
    </div>
    <div
      class="modal-background"
      v-on:click="[isActive = false, setBodyScroll(isActive)]"
    ></div>
  </div>
</template>

<script>
  export default {
    name: 'ModalImage',
    props: {
    },
    components: {
    },
    data () {
      return {
        isActive: false,
        imgSrc: "",
      }
    },
    methods: {
      openModal(src) {
        this.isActive = true
        this.imgSrc = src
        this.setBodyScroll(this.isActive)
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .modal {
    &-wrapper {
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      transition-duration: $duration;
      opacity: 0;
      pointer-events: none;

      @media #{$device-l} {
      }

      &.is-active {
        opacity: 1;
        pointer-events: auto;
      }
    }

    box-sizing: border-box;
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: auto;
    max-width: calc(100% - 60px);
    margin: #{44px + 10px} auto 0;

    @media #{$device-l} {
    }

    &-header {
      position: absolute;
      bottom: 100%;
      right: 0;
      display: flex;
      margin-bottom: 10px;

      @media #{$device-l} {
      }
    }

    &-close {
      appearance: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 44px;
      height: 44px;
      padding: 0;
      border: none;
      color: #fff;
      background-color: transparent;
      cursor: pointer;

      @media #{$device-l} {
      }

      &-icon {
        $width: 4px;

        display: block;
        width: 28px;
        height: 28px;
        position: relative;
        margin: 0 auto;

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          display: block;
          width: $width;
          height: 100% * √(2);
          background-color: #fff;
          transform-origin: top center;
          border-radius: $width;
        }

        &::before {
          left: 0;
          transform: translateX(-#{$width / 2}) rotate(-45deg);
        }

        &::after {
          right: 0;
          transform: translateX($width / 2) rotate(45deg);
        }
      }

      &-text {
        text-align: center;
        font-size: 1.0rem;
        text-transform: uppercase;
      }
    }

    &-body {
      $height: calc(100vh - #{44px + 10px + 60px});

      margin: 0 auto;

      @media #{$device-l} {
      }

      img {
        display: block;
        max-width: 100%;
        max-height: $height;
        margin: 0 auto;
        object-fit: contain;
      }
    }

    &-background {
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      margin: auto;
      background-color: rgba(#333, 0.6);
      cursor: pointer;

      @media #{$device-l} {
      }
    }
  }
</style>
