<template>
  <button
    class="page-scroll"
    :class="{'is-show': scroll > 0}"
    @click="pageScroll"
  >
    <span class="page-scroll-icon"></span>
    <span class="page-scroll-text">PageTOP</span>
  </button>
</template>

<script>
  export default {
    name: 'PageScroll',
    data () {
      return {
        windowHeight: 0,
        scroll: 0,
      }
    },
    methods: {
      setScroll () {
        if (this.scTimer) return
        this.scrollTimer = setTimeout(() => {
          this.scroll = window.scrollY
          clearTimeout(this.scrollTimer)
          this.scrollTimer = 0
        }, 100)
      },
      pageScroll () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },
    },
    created () {
      this.windowHeight = window.innerHeight
    },
    mounted () {
      window.addEventListener('scroll', this.setScroll)
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .page-scroll {
    $size: ( "sp": 50px, "pc": 60px );

    box-sizing: border-box;
    position: fixed;
    z-index: 70;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: map-get($size, "sp");
    height: map-get($size, "sp");
    padding: 0;
    border: none ;
    font-size: 0.8rem;
    color: #707070;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    appearance: none;
    cursor: pointer;
    transition-property: opacity;
    transition-duration: $duration;

    opacity: 0;
    pointer-events: none;

    @media #{$device-l} {
      right: 10px;
      width: map-get($size, "pc");
      height: map-get($size, "pc");
    }

    @media #{$device-dark} {
      color: map-get($color-dark, "text");
    }

    &.is-show {
      opacity: 1;
      pointer-events: auto;
    }

    &-icon {
      height: 10px;
      transform: scaleY(0.9);
      transform-origin: center center;

      &::before {
        $size: 14px;

        content: "";
        display: block;
        width: $size;
        height: $size;
        border: 2px solid map-get($color-brand, "main");
        border-left: none;
        border-bottom: none;
        transform: rotate(-45deg);
        transform-origin: inherit;

        @media #{$device-l} {
        }
      }
    }

    &-text {
      margin-top: 4px;

      @media #{$device-l} {
      }
    }
  }
</style>
