<template>
  <div class="l-container">
    <Header />
    <Main>
      <div class="wrap">
        <Post :post="post" :around-post="aroundPost" />
      </div>
      <Modal ref="ModalImage"></Modal>
      <LinkList>
        <li>
          <LinkBox
            :path = "{name: 'Founder'}"
          />
        </li>
        <li>
          <LinkBox
            :path = "{name: 'Company'}"
          />
        </li>
      </LinkList>
    </Main>
    <Footer />
  </div>
</template>

<script>
  /* eslint no-useless-escape: 0 */
  import SiteInfo from '@/assets/json/siteinfo.json'
  import NewsPosts from '@/jsons/news.json'

  import Header from '@/components/essentials/Header.vue'
  import Main from '@/components/essentials/Main.vue'
  import Footer from '@/components/essentials/Footer.vue'

  import Post from '@/components/news/NewsPost.vue'
  import Modal from '@/components/common/ModalImage.vue'

  import LinkList from '@/components/common/LinkList.vue'
  import LinkBox from '@/components/common/LinkBox.vue'

  export default {
    name: 'NewsPost',
    components: {
      Header,
      Main,
      Footer,
      Post,
      Modal,
      LinkList,
      LinkBox,
    },
    data () {
      return {
        SiteInfo: SiteInfo,
        pageTitle: 'ニュースルーム',
        newsList: NewsPosts.contents,
        post: {},
        aroundPost: {},
        postImages: [],
      }
    },
    created () {
      this.setPostDatas()
    },
    computed: {
    },
    watch: {
      '$route' () {
        this.setPostDatas()
        this.$emit('updateHead')
      },
    },
    mounted () {
    },
    methods: {
      setPostDatas () {
        this.getPostData()
        this.$nextTick(() => {
          this.setImageSrc()
        })
      },
      getPostData () {
        const index = this.newsList.findIndex(current => current.id === this.$route.params.postId)
        this.aroundPost = {}

        if (index < 0) {
          this.$router.push({ name: "NewsList" })
        }
        else {
          this.post = JSON.parse( JSON.stringify(this.newsList[index]) )
          this.post.body = this.post.body.replace(/<br><br>/g, '</p><p>')
          this.post.body = this.post.body.replace(/<br><img/g, '</p><img')
          this.post.body = this.post.body.replace(/<p><img(.*?)>[<br>]*(<(?!(?:[img]|p))>)<\/p>/g, '<figure><img$1><figcaption>$2</figcaption></figure>')
          this.post.body = this.post.body.replace(/<p><br>/g, '<p>')
          this.post.body = this.post.body.replace(/<li><strong>(.*?)<\/strong>/g, '<li><strong>$1<\/strong><br>')
          this.post.body = this.post.body.replace(/href="http(?:s):\/\/(?:www\.)meta-mo\.co\.jp\/(\S*)" target="_blank"/g, 'href="/$1"')
          this.post.body = this.post.body.replace(/target="_blank"(.*?)>(.*?)<\/a>/g, `target="_blank"$1>$2<img src="//images.microcms-assets.io/protected/ap-northeast-1:79f48203-15c6-46d3-84bd-811a68974770/service/metamo/media/icon_launch.svg" alt="" /></a>`)

          this.post.body = this.post.body.replace(/<h5/g, '<p class="is-bold"><strong')
          this.post.body = this.post.body.replace(/<\/h5>/g, '<\/strong></p>')
          this.post.body = this.post.body.replace(/<h4/g, '<h6')
          this.post.body = this.post.body.replace(/<\/h4>/g, '<\/h6>')
          this.post.body = this.post.body.replace(/<h3/g, '<h5')
          this.post.body = this.post.body.replace(/<\/h3>/g, '<\/h5>')
          this.post.body = this.post.body.replace(/<h2/g, '<h4')
          this.post.body = this.post.body.replace(/<\/h2>/g, '<\/h4>')
          this.post.body = this.post.body.replace(/<h1/g, '<h3')
          this.post.body = this.post.body.replace(/<\/h1>/g, '<\/h3>')

          this.post.body = this.post.body.replace(/(?!<blockquote>)<span style="font-size: 0\.75em">※(.*?)<\/span>/g, '<sup>※$1</sup>')
          this.post.body = this.post.body.replace(/<blockquote>※/g, '<blockquote>')

          if ( index > 0 ) {
            this.aroundPost.prev = {
              id: this.newsList[index-1].id,
              categoryId: this.newsList[index-1].category.id,
            }
          }

          if ( index < this.newsList.length ) {
            this.aroundPost.next = {
              id: this.newsList[index+1].id,
              categoryId: this.newsList[index+1].category.id,
            }
          }
        }
      },
      setImageSrc() {
        const body = document.getElementById("post-body")
        this.postImages = Array.prototype.slice.call( body.getElementsByTagName("img") )

        this.postImages.forEach((img) => {
          img.addEventListener("click", function() {
            let index = this.postImages.indexOf(img)
            this.$refs.ModalImage.openModal(this.postImages[index].src)
          }.bind(this))
        })
      },
    },
    head: {
      title () {
        return {
          inner: this.post.title + ' | ' + this.pageTitle,
        }
      },
      meta () {
        return this.defaultHead('meta', [
          { property: 'og:title', content: this.post.title + ' | ' + this.SiteInfo.titleShort },
          { property: 'og:url', content: SiteInfo.baseURL + location.pathname },
          { property: 'og:image', content: (this.post.thumbnail) ? this.post.thumbnail.url : SiteInfo.baseURL+SiteInfo.ogp.image },
        ])
      },
      link () {
        return this.defaultHead('link', [
          { rel: 'canonical', href: this.SiteInfo.baseURL + location.pathname },
        ])
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";
</style>
