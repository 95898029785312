import DefaultHead from '@/configs/DefaultHead'

export default {
  methods: {
    setBodyScroll (toggle) {
      let top
      if (toggle) {
        top = window.scrollY * -1
        document.body.style.position = 'fixed'
        document.body.style.overflowY = 'scroll'
        document.body.style.top = `${top}px`
      }
      else {
        top = document.body.style.top
        document.body.style.position = ''
        document.body.style.overflowY = ''
        document.body.style.top = ''
        window.scrollTo(0, parseInt(top || '0') * -1)
      }
    },
    setClipBoardAlert (copyText, alertText = '') {
      this.$copyText(copyText).then(function() {
        alert( alertText + '「' + copyText + '」をコピーしました' );
      }, function() {
        alert("コピーできませんでした。ページを更新してもう一度お試しください。")
      })
    },
    defaultHead (name, overwrites) {
      let heads = overwrites

      DefaultHead[name]().forEach(value => {
        let exist = false

        overwrites.forEach(overwrite => {
          if (exist) return
          ['name', 'property', 'rel'].forEach(id => {
            if (exist) return
            exist = (value[id] && value[id] === overwrite[id])
          })
        })

        if (!exist) {
          heads.push(value)
        }
      })
      return heads
    },
  }
}
