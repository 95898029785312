<template>
  <div class="member">
    <div class="wrap">
      <ul class="member-list">
        <li
          v-for="(member, index) in founderDatas"
          :key="index"
        >
          <div
            :id="member.id"
            class="member-section"
          >
            <div
              class="member-introduction"
              :class="{'is-white': member.isWhite}"
            >
              <figure>
                <img
                  :src="require('@/assets/images/founder/founder_'+member.id+'.jpg')"
                  alt=""
                >
                <figcaption>
                  <h3 class="member-name">
                    {{ member.position }} {{ member.name }}
                  </h3>
                  <p
                    class="member-lead"
                    v-html="member.lead"
                  ></p>
                </figcaption>
              </figure>
            </div>
            <div class="member-text">
              <p
                v-for="text in member.text"
                v-html="text"
                :key="text"
              ></p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FoundMember',
    components: {
    },
    data () {
      return {
        founderDatas: [
          {
            'isWhite': false,
            'id': 'yuta',
            'position': '代表取締役',
            'name': '佐藤由太',
            'lead': '適者生存の課題解決型企業<br>人間を自由にするデータテクノロジーカンパニー',
            'text': [
              'ベビーシッターのマッチング・サービスを利用した際に生まれたちょっとした疑問。思えばそれが始まりでした。',
              '<span>なぜ、目の前にいるベビーシッターさんは、自身の働きぶりに関する他己評価データを自分自身で管理・運用できる権限（仕組み）が無いのだろう――。</span>',
              'それは私自身も例外ではなく、この気付きが私たちのチームにとってターニングポイントになりました。',
              '現在、様々なサービスにおいて評価データとサービスが密結合しており、サービスが終了する事態に陥れば個々が積み重ねてきた評価という "歴史" が消え、高評価を頼りに仕事を請け負っていた人々が大打撃を受けることは避けられません。もちろん、依頼する側も困ってしまいます。',
              'このようなことはマッチングサービスを活用しているフリーランサー以外にも言えることであり、こと日本においては雇用の流動化が進む今だからこそ、人事評価や日々の就業記録を勤務先の企業のみが保有し続けることはデメリットになり得ます。',
              '実は、雇う立場の企業にとっても同じことであり、働く者は自身を十分にアピールしたい一方で、企業は求職者についてなるべく多くのことを知りたいものです。この「情報の非対称性」が及ぼす労働市場への悪影響は経済活動を停滞させる要因になりかねないと考えています。',
              'さらに、日本以外の多くの国々では雇用が極めて流動的であり、私たちはこれらの課題を解決するための第一歩として、あえて国外のベトナムにおいて物理的カードを利用したパーソナルデータサービスを提供し始めました。幸運なことに、その成長スピードは市場が広いことの優位性を示してくれています。',
              '事業のキッカケを具現化した『自身のデータを安心安全に、そして気軽に持ち運ぶことができたら素晴らしい』という想い。',
              'しかし、それだけでは足りない。私たちは解決すべき課題の規模に対して貪欲でした。',
              '論点に近づくために、議論・インタビューを重ね、書籍・論文を読み漁るために国立国会図書館にも通い詰め、本質に迫ろうとしていました。私たちが解決すべき課題（とその事業）は少しずつ本質に近づいて行き、半年後には現在のミッションに辿り着いたのです。',
              'それは「<em>自分自身のあらゆるコトをデータ化し、そのデータを扱いやすく、選択的に、公開、共有できる仕組みを人々に提供する</em>」ということであり、私たちにとっては『人間を自由にする道具』の創造に他なりません。',
              'ところが、私たちが解決を試みる領域に近い「パーソナルデータ」の利用について俯瞰すると、情報売買・情報銀行といった自由とは程遠いものばかりであり、世界各国でその是非が問われています。だからこそ私たちは論点を考え抜き、その手法とプロセスを洗練させているのです。',
              '経済原理に則り、企業がより一層にテクノロジーを活用しようとすればするほど、消費者との間に深い溝が生まれています。テクノロジーは生活を便利にしてくれる魔法のような存在であったはずであるのに。',
              'たしかに、第二次産業革命が起きた120年前と比較すると、効率化の恩恵でヒト・モノ・カネの流れは格段に良くなり、こと先進国においては、危険な仕事をロボットが行うことで労働環境も改善されています。',
              'ところが、第三次産業革命の「情報革命」で生み出されるデータが絡むと人々は息苦しさを感じるようになります。まるでビッグ・ブラザーが登場するディストピアを想像してしまうのです。',
              '1984年1月。アメリカン・フットボールのチャンピオンを決めるスーパーボウルで流れた伝説的テレビCM「1984（Apple Computer）」。ビッグ・ブラザーを打ち破り、Macintoshの登場によって1984年がジョージ・オーウェルのSF小説「1984」のようにならないことを明示したものでした。',
              'そんなAppleは、現代では欠かせないものとなった全画面タッチスクリーンを備えたスマートフォンを世に送り出し、スマートフォンの定義までをも変えてしまいました。その影響は絶大であり、対抗馬として語られるAndroidの方針を大きく変えてしまうほどであったのです。',
              '「一家に一台のPC」から「常に持ち歩くスマートフォン」に変貌を遂げた今。以前よりも情報と人々の距離が近くなればなるほど、社会の中における情報の在り方とその利用方法を廻って社会が揺れています。',
              'そんな時代だからこそ、私たちは「ヒト」と「情報」の関係を見直すことにしました。その実現のためには『リベラルアーツとテクノロジーの交差点に立つ必要があること』については先人が教えてくれています。',
              'だからこそ、私たちはリベラルアーツとテクノロジーの交差点に立ち続け、人間を自由にするデータテクノロジー・カンパニーとして、第四次産業革命の担い手となるプロダクトを世に送り続けて参ります。',
            ],
          },
          {
            'isWhite': true,
            'id': 'maina',
            'position': 'CDO',
            'name': '柴田舞奈',
            'lead': 'デジタルを越えた先にある『人間中心設計』<br>メタモの目指す未来とデザイン',
            'text': [
              '私たちメタモが目指す理想の未来は、データと人間が本当の意味で仲の良い社会を創ることです。',
              '今、人々のパーソナルデータが本人の意志と違った形で、知らぬ間にデータの海の一部として使われています。データは「0」「1」の羅列に過ぎないという客観的な捉え方もありますが、視点を変えてみると違った捉え方ができると確信しています。個々が持つ大切な「自分の分身」「思い出」であり「宝物」でもあるのではないかと私たちは考えているのです。<br>その大切な宝物であるはずのデータが知らぬ間に扱われてよいものなのか強く疑問を抱き、新しい仕組みと社会を創る必要があると感じました。',
              'だからこそ私たちは、データと接する全ての人がデータに振り回されるのではなくデータを取捨選択管理でき、データを利用して自らがより自由に生きることのできるサービスをデザインしています。そのためには「テクノロジー」をより良く「デザイン」することが必要不可欠であるということに辿り着いたのです。',
              'デザインをする上で私たちは『人間中心設計のサービス』を基本とし、「なぜ・誰のために・いつ・どうやって・どのような場所で・何を伝えるか」という様々な場面に思考を巡らせ、デザイナー・ノンデザイナー・エンジニアという従来の枠組みを越えたチームで取り組んでいます。',
              'スマートフォン登場以前と比較すると、今は電話という機能はもはや脇役になり、高画質のカメラで心躍る写真を撮り、SNSでシェアをする。チャットツールでは、文字・絵文字・スタンプなどで常に繋がり合うという情報と人との関係が驚くほどに変化しました。',
              'この私の手中に収まるスマートフォンはとても便利で、朝のアラームに始まり、移動中の情報収集、ストリーミング動画配信の視聴まで、現代にとってかかせない存在です。そして、時には写真・動画がSNSでシェアされ、人々の心を揺さぶり、国や文化に対する考え方をも変える時代になりました。その結果、作り手に大きな責任が求められ、プロダクトを作る際に考えなくてはならない要素が複雑化しています。それに伴って、デザインの領域もわずか数年前では考えられなかったほど、多岐に渡るようになりました。',
              'ユーザーが手軽に大量の情報に触れる時代だからこそ、私たち作り手は、今一度、人間に寄り添うことが大切であり、そうして生まれたデザインが「良いデザイン」であると私たちは考えています。',
              'どこか冷たく感じる電子データも私たちの考える人間を中心とした「良いデザイン」が組み合わさることで、デジタルを越えたその向こう側の人々・社会・文化に対して、唯一無二のかけがえのない感動を与え、素晴らしい未来になると信じています。',
            ],
          },
          {
            'isWhite': false,
            'id': 'hiroki',
            'position': 'CSO',
            'name': '齋藤洋希',
            'lead': '事業判断におけるマインド<br>―誰もが「欲しい」サービスを目指して―',
            'text': [
              '「齋藤さんは、何故この事業に取り組もうと思いましたか」という質問を、社交辞令の常套句のごとくいただくことがあります。',
              '建前上、繕った回答をすることもありますが、本音を述べてしまうと「私が欲しいサービスか否か」というのが事業に注力するための大きな原動力になっています。ただし、この回答だけですと、少し投げやりな感じになってしまうため、背景についてご説明させてください。',
              '私たちメタモでは、日々、メタモの事業内容を起点とした様々な新規事業や新サービスのアイデアが生まれています。もちろんそれらのアイデアすべてに対して、資金や開発力などのリソースを平等にかつ満遍なく割り振ることが出来るのであれば、何も悩むことはありません。しかし、現実はそう簡単にはいかない場面に多々遭遇します。また、既存事業においては、成長を具体的に描くだけでなく、実行していくことも重要になります。',
              'このような状況において、「今やるべきこと」「今後やるべきこと」「まだできないこと」「やらないこと」などを多角的な視点と、一歩離れた位置から俯瞰して、現時点のメタモにとってどのような判断が最良なのかということを想定し、適切に判断を下すことが求められてきました。',
              'これらの判断を行う際の基軸になるものとして、「メタモがとるべき選択肢は何であるか」ということを強く意識しています。',
              'さて、冒頭では事業に取り組む大きな原動力を「私（わたし）」と紹介し、自己を優先している記述をしましたが、実際は「メタモ」のことを優先した決断をしています。一見すると、矛盾した内容に映るかもしれません。自分の意志を主張せず事業（会社）のために身を粉にするだけの存在なのではないかと。',
              '私はメタモが創り出すプロダクトを信じています。',
              'それはこれまでの軌跡を振り返るとより確固たるものになります。一方で、妄信的にならないように気をつけていることがあります。',
              'つまり、「メタモが欲しいものは、私も欲しいもの」となるが「私が欲しいものは、メタモが欲しいものであるとは限らない」ということを強く意識しています。',
              '事業を推進していく際にとても大切なのが熱意です。しかし、独りよがりの熱意は時に空回りし、視野を狭め、最悪の場合チームに悪影響を及ぼします。私の場合幸運なことに、メタモが欲しいものは、私が欲しいものとして置き換えることができる存在であったこともあり、バランスを保ちながら事業に取り組むことができています。',
              'メタモが欲しいものを、私が欲しいだけに留まらせぬことがないよう、世界中の一人でも多くの方に「欲しい」と感じていただけることを目指して、戦略的に事業に邁進して参ります。',
            ],
          },
        ],
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .member {
    margin: 50px 0 100px;

    @media #{$device-s} {
      margin: 100px 0 50px;
    }

    &-list {

      @media #{$device-l} {
      }

      >li {
        margin-top: 50px;

        @media #{$device-l} {
          margin-top: 100px;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &-section {

      @media #{$device-l} {
      }
    }

    &-introduction {
      position: relative;
      color: map-get($color-text, "base");
      margin-bottom: 50px;

      @media #{$device-s} {
        margin-right: -30px;
        margin-left: -30px;
      }

      @media #{$device-l} {
        margin-bottom: 100px;
      }

      &::before {
        content: "";
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60%;
        max-height: 200px;
        background-image: linear-gradient(to bottom,
          rgba(#fff,   0)   0%,
          rgba(#fff, 0.5)  51%,
          rgba(#fff, 0.8) 100%
        );

        @media #{$device-l} {
          max-height: 220px;
        }
      }

      img {
        display: block;
        width: 100%;

        @media #{$device-l} {
        }
      }

      figcaption {
        position: absolute;
        z-index: 20;
        right: 0;
        bottom: 0;
        width: 75%;
        max-width: 540px;
        margin: 25px 30px;
        text-align: right;

        @media #{$device-s} {
          min-width: 260px;
          margin: 10px 30px;
        }

        @media #{$device-l} {
        }
      }

      &.is-white {
        color: #fff;

        &::before {
          background-image: linear-gradient(to bottom,
            rgba(#292929,   0)   0%,
            rgba(#292929, 0.5)  51%,
            rgba(#292929, 0.8) 100%
          );
        }
      }
    }

    &-name {
      margin-bottom: 25px;
      font-size: 2.0rem;
      font-weight: bold;

      @media #{$device-s} {
        margin-bottom: 10px;
      }

      @media #{$device-l} {
        font-size: 3.2rem;
      }
    }

    &-lead {
      line-height: 2.0;

      @media #{$device-s} {
        width: 100%;
        max-width: calc(100vw - 60px);
        margin-left: auto;
        line-height: 1.7;
        font-size: 1.0rem;
      }

      @media #{$device-l} {
      }
    }

    &-text {
      width: auto;
      max-width: 600px;
      margin: 0 auto;
      line-height: 2.0;
      color: #626262;

      @media #{$device-dark} {
        color: map-get($color-dark, "text");
      }

      @media #{$device-l} {
        width: 70vw;
        max-width: 720px;
      }

      p {
        margin-top: 25px;

        @media #{$device-l} {
        }

        &:first-child {
          margin-top: 0;
        }
      }

      ::v-deep {
        span {
          font-style: oblique;
        }

        em {
          font-weight: bold;
        }
      }
    }
  }
</style>
