<template>
  <div class="map" ref="map">
    <MglMap
      :accessToken="accessToken"
      :mapStyle="mapStyle"
      :zoom="mapZoom"
      :center="mapCenter"
    >
      <MglMarker
        v-for="(marker, index) in geojson.features"
        :key="index"
        :offset = "mapOffset"
        :coordinates = "marker.geometry.coordinates"
      >
        <img
          slot="marker"
          :src="require('@/assets/images/company/map_pin.png')"
          :width="marker.properties.iconSize[0]"
          :height="marker.properties.iconSize[1]"
        />
      </MglMarker>
    </MglMap>
  </div>
</template>

<script>
  import 'mapbox-gl/dist/mapbox-gl.css'
  import { MglMap, MglMarker } from 'vue-mapbox';

  export default {
    name: 'CompanyMap',
    components: {
      MglMap,
      MglMarker,
    },
    data () {
      return {
        accessToken: 'pk.eyJ1IjoibGVtb25kbTciLCJhIjoiY2p5dGNudWQ4MDJqcDNtbnl6OWhqaTZxbSJ9.V7aKyCevxMqRSjbDQ3IjEg',
        zoom: { pc: 1.25, sp: 0.25 },

        // 見た目
        mapStyle: 'mapbox://styles/lemondm7/cjyvig12g125f1cpn8dg1nrp8',

        // 地図の中心地
        center: {
          pc: { lon: 32.751913, lat: 7.279669 },
          sp: { lon: 72.751913, lat: 20.279669 }
        },

        offset: {
          pc: [ 5, -15 ],
          sp: [ 2, -13 ]
        },

        geojson: {
          "type": "FeatureCollection",
          "features": [
            {
              "type": "Feature",
              "properties": {
                "message": "Mumbai",
                "iconSize": [20, 25],
              },
              "geometry": {
                "type": "Point",
                "coordinates": [
                   72.890691,
                   19.125762
                ]
              }
            },
            {
              "type": "Feature",
              "properties": {
                "message": "Tokyo",
                "iconSize": [20, 25],
              },
                "geometry": {
                  "type": "Point",
                  "coordinates": [
                    139.732002,
                     35.685664
                  ]
                }
            },
            {
              "type": "Feature",
              "properties": {
                "message": "Ho chi minh",
                "iconSize": [20, 25],
              },
              "geometry": {
                "type": "Point",
                "coordinates": [
                  106.703051,
                   10.764749
                ]
              }
            },
            {
              "type": "Feature",
              "properties": {
                "message": "Kuala lumpur",
                "iconSize": [20, 25],
              },
              "geometry": {
                "type": "Point",
                "coordinates": [
                  101.709649,
                    3.152988
                ]
              }
            },
          ]
        },
        mapWidth: 0
      }
    },
    mounted () {
      window.addEventListener('resize', this.setMapWidth)
      this.setMapWidth()
    },
    beforeDestroy () {
      window.addEventListener('resize', this.setMapWidth)
    },
    computed: {
      mapCenter () {
        return (this.mapWidth >= 768) ? this.center.pc : this.center.sp
      },
      mapZoom () {
        return (this.mapWidth >= 768) ? this.zoom.pc : this.zoom.sp
      },
      mapOffset () {
        return (this.mapWidth >= 768) ? this.offset.pc : this.offset.sp
      },
    },
    watch: {
    },
    methods: {
      setMapWidth () {
        this.mapWidth = this.$refs.map.clientWidth
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .map {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: auto;
    max-height: 320px;
    margin: 50px 0 40px;

    @media #{$device-l} {
      height: 40vh;
      min-height: 400px;
      max-height: 640px;
    }

    &::before {
      content: "";
      display: block;
      padding-top: 65%;

      @media #{$device-l} {
        content: none;
      }
    }

    .mgl-map-wrapper {
      &,
      .mapboxgl-map {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    }
  }

  .mapboxgl-ctrl-attrib-inner {
    display: none;
  }
</style>
