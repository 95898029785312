<template>
  <div id="app">
    <router-view />
    <Loading v-if="loadingShow" :done="loadingDone" />
    <PreResourceLoader />
    <ToChrome />
  </div>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'
  import Loading from '@/components/essentials/Loading.vue'
  import PreResourceLoader from '@/components/essentials/PreResourceLoader.vue'
  import ToChrome from '@/components/essentials/ToChrome.vue'

  export default {
    components: {
      Loading,
      PreResourceLoader,
      ToChrome,
    },
    methods: {
      setLoadingEvent () {
        if (document.readyState === 'complete') return

        this.loadingShow = true
        window.addEventListener('load', () => {
          this.loadingDone = true
        })
      },
    },
    data () {
      return {
        SiteInfo: SiteInfo,
        loadingDone: false,
        loadingShow: false,
      }
    },
    created () {
      this.setLoadingEvent()
    },
    mounted () {
    },
  }
</script>

<style lang="scss">
</style>
