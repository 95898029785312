<template>
  <span class="logo">
    <logo />
  </span>
</template>

<script>
import Logo from '@/assets/images/common/site_logo.svg'

export default {
  name: 'BrandLogo',
  components: {
    Logo
  },
  props: {
  }
}
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .logo {
    position: relative;
    display: block;
    width: 100%;

    &::before {
      content: "";
      display: block;
      padding-top: 117.34 / 451.16 * 100%;
    }

    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;

      [class*=text] {
        fill: #666766;
        transition-duration: $duration;

        @media #{$device-dark} {
          fill: map-get($color-dark, "text");
        }
      }
    }

    &.is-white {
      svg {
        [class*=text] {
          fill: #fff;
        }
      }
    }
  }
</style>
