<template>
  <div class="l-container">
    <Header />
    <Main>
      <section>
        <Heading
          level = "2"
          :jp-text = "pageTitle+'と通知'"
          en-text = "Privacy Policy"
        />
        <div class="wrap">
          <LeadText align-class="left">
            <p>
              このプライバシーポリシーは2020年1月16日に初版が公開されました。<br>
              プライバシーポリシーの発効日は2020年1月16日（JST/日本標準時）です。
            </p>
            <p>
              プライバシーポリシーが改定された場合は、改定前のバージョンを適切なページを通じてダウンロードすることが可能になります。初版ですので、初版にはダウンロードするためのページリンクがありません。
            </p>
            <p>
              メタモは、利用者である皆様のプライバシーをとても大切にしており、重要なものであると認識した上で取り扱っております。そこで、利用者の個人情報について、メタモはどのように収集および利用し、開示手続き、移転（注：エクスポート[ Export ]と呼ばれることもあります）および保存についてのポリシーとルールを定めました。そして、そのルールとポリシーを掲示しているのが「プライバシーポリシー」です。
            </p>
            <p>
              プライバシーポリシーに記載された内容やプライバシーポリシーについて、疑問やご質問がある場合は「
              <a
                href="javascript: void(0)"
                @click="setClipBoardAlert(SiteInfo.mail, 'メールアドレス')"
              >&#105;&#110;&#102;&#111;&#64;&#109;&#101;&#116;&#97;&#45;&#109;&#111;&#46;&#99;&#111;&#46;&#106;&#112;</a>
              」までご連絡ください。
            </p>
          </LeadText>
          <Reading>
            <section>
              <h3>はじめに</h3>
              <p>ここからは、厳密な表記をおこなうために難しいと感じる内容が含まれるかもしれません。しかし、利用者である皆様にとって大切なものです。したがって、利用者の皆様は十分にお読み頂き、ご理解して頂いた上で、ご承諾または拒否の判断を行ってください。</p>
              <p>プライバシーポリシーで規定されるデータは、表記が「個人情報」「Cookie」「ビーコン」「パーソナルデータ」などを一例として様々なものが存在します。メタモではそれぞれについて細かく規定してプライバシーポリシーを掲示しています。</p>
            </section>
            <section>
              <h3>Chapter 1 概要</h3>
              <section>
                <h4>Art. 1 適用範囲</h4>
                <p>このプライバシーポリシーと通知（以下「本通知」といいます）では、メタモ株式会社（日本法人 / Metamo k.k.、以下「メタモ」といいます ）が個人データを収集し、利用する目的と方法について説明します。</p>
                <p>本通知は国または地域に関係なく、メタモが提供するWebサイト（アドレス：https://www.meta-mo.co.jp、以下「当ウェブサイト」といいます）を利用する全ての皆様（ユーザー）に適用されるものです。ただし、メタモが別途に提供する個別サービスには、個別サービスに応じたプライバシーを大切に扱うための個別の規定が本通知と同様に設けられており、本通知は適用されません。これは、個別サービスごとに適切なプライバシーポリシーを規定することを意味しており、一つのプライバシーポリシーで全てのサービスを包摂する内容ではなく、細かくポリシーとルールを定めていることを意味しています。</p>
                <p>分かりやすくするために、本通知が適用される対象を次に記します。</p>
                <ul>
                  <li>利用者：メタモが提供するWebサイトに何らかの方法でアクセスして利用する個人</li>
                </ul>
                <p>メタモにおけるデータの利用方針は、メタモが事業を展開する地域を管轄する国・地域における法が適用されます。ですから、特定の国および地域において、当該国および地域の法律で認められる場合に限って本通知に記載されている利用方針を実施します。これは、特定国および地域が一層に厳しい制限を課している場合、法律を優先することを意味するものです。</p>
              </section>
              <section>
                <h4>Art. 2 データの管理者と移転</h4>
                <p>EEA（欧州経済領域）および英国（グレートブリテン及び北アイルランド連合王国）においても、当ウェブサイトに関して収集された個人データについては、メタモ（Metamo k.k.）がデータ管理者となります。</p>
                <p>個人データは、利用者であるあなたの大切なものです。ですから、メタモのデータ利用方針に関して、ご質問、ご意見、ご要望ならびに苦情がある場合は、info@meta-mo.co.jpを宛先にして電子メールでご連絡ください。</p>
              </section>
            </section>
            <section>
              <h3>Chapter 2 データの収集と利用</h3>
              <section>
                <h4>Art. 1 メタモが収集するデータの種類</h4>
                <p>メタモは次のデータを収集します。理由や使用方法はArt. 2に続きます。</p>
                <ul>
                  <li>当ウェブサイトにアクセスした際の利用状況</li>
                  <li>OS、ウェブブラウザ、ウェブページレンダリングエンジン、IPアドレス、言語設定、使用言語、機器の固有識別子、広告識別子、機器のセンサー等から得られるモーションデータおよび通信会社のバージョン、種類、種別等の情報</li>
                  <li>IPアドレスや識別子から分かる範囲のアクセス源に関する情報（地理、組織の情報を含みます）</li>
                  <li>Cookieを利用した利用者の氏名や住所を特定できない形で識別するための一時的なID</li>
                </ul>
              </section>
              <section>
                <h4>Art. 2 個人データを収集する理由と使用方法</h4>
                <p>メタモが、利用者に関する情報を取得する理由は、取得したデータを集計して得られた情報を基にして、当ウェブサイトの表示速度を改善したり、ユーザーフレンドリーなナビゲーションを考慮したり、コンテンツ内容の充実を図ったりする為です。詳細な項目は下記に続きます。</p>
                <ul>
                  <li>当ウェブサイトの提供のため</li>
                  <li>当ウェブサイトにおける利用者の利便性を向上させるため</li>
                  <li>記事、画像・音声・動画等のメディアを含むコンテンツ作成のため</li>
                  <li>ご意見、ご感想、ご要望、苦情などのお問い合わせ等に回答するため</li>
                  <li>当ウェブサイトのアクセス履歴を基にして、自社サービスの提供地域、提供環境、提供方法等のマーケティングプラン策定のため</li>
                  <li>メタモが第三者に依頼して自社または自社が提供するサービスの広告を効率的に配信するため（興味関心に連動した広告も含みます）</li>
                  <li>メタモが第三者に依頼して配信した広告の効果を計測するため</li>
                </ul>
              </section>
              <section>
                <h4>Art. 3 データの共有と開示</h4>
                <p>メタモは、利用者のアクセスに関する情報収集のために、Google Analytics を利用しています。Google Analytics は、Webブラウザ等の機能であるCookieと識別子を用いて利用者の情報を収集します。ですから、アクセスに関する情報はユーザーの氏名や住所を特定できない識別子を用いて、当ウェブサイトのアクセス行動に関する情報が Google Analytics のサービスを通じて、Google Analytics を提供する企業と共有されます。分かりやすさのために端的に表現すると、Google または Google の関連会社と情報共有されることを意味します。</p>
              </section>
              <section>
                <h4>Art. 4 公的機関への開示と紛争時の利用</h4>
                <p>メタモは、適用される法律、省令等の規制により、法的手続きに係る場合または公的機関から開示を求められた場合、法律で定められた適切な手続きで妥当であるとメタモが判断した場合に利用者に関する情報を共有することがあります。</p>
                <p>なぜなら、メタモは当ウェブサイトの提供にあたり、メタモの権利および財産、他者の権利、安全および財産を保護する社会的な責任があるからです。</p>
                <p>日本の法令で定められる一般的な表記に基づき、以下は、難しく感じる表記で利用目的を表示しています。</p>
              </section>
              <section>
                <h4>Art. 5 データの保持と消去</h4>
                <p>メタモは、利用者に関する個人データを保持します。利用者は、メタモが保持する個人データについて、利用者から予め定められた様式を用いた申請があった場合、申請者の個人データを消去します。しかし、メタモが法令遵守義務を負う場合は、消去申請があったとしても申請者の個人データを保持し続けることがあります。代理人が申請する場合も同様とします。</p>
                <p>例えば、メタモのWebサイトにDoS/DDos攻撃などに代表されるサイバー攻撃が行われた場合、その発信元に関する情報を保持し続け、サービスの正常提供のためにブロッキングを行なう等の方法で利用することができることを意味します。また、メタモがサイバー攻撃の被害にあったことを当局に届ける際にも利用されることがあります。</p>
                <p>利用者は「<a href="//tools.google.com/dlpage/gaoptout/" target="_blank">ブラウザ用のGoogle アナリティクス オプトアウト アドオン<BlankIcon /></a>」を利用することで、利用者に関する情報をGoogleのルールに従って削除することができます。削除に関して不明な事がある場合はinfo@meta-mo.co.jpまでお問い合わせください。</p>
              </section>
            </section>
            <section>
              <h3>Chapter 3 個人データの開示・訂正・利用停止の申請</h3>
              <p>個人データの開示、訂正、利用停止等についてはinfo@meta-mo.co.jp宛に電子メールでお問い合わせください。申請方法については、適用される国や地域に従ったものをご案内いたします。メタモは、利用者からの申請を真摯に受け止め、速やかに対応します。</p>
              <p>電子メールでの返信時にもご案内いたしますが、個人データの開示、訂正、利用停止、苦情がある場合は、info@meta-mo.co.jp宛に電子メールでお問い合わせ頂いた後に、下記の宛先まで郵送にてお送りください。</p>
              <section>
                <h5>Metamo k.k. 個人データ適正利用委員会</h5>
                <p>
                  〒102-0083 東京都千代田区麹町6-6-2 東急四谷ビル5階<br>
                  メタモ株式会社  個人情報適正利用委員会 宛
                </p>
              </section>
            </section>
            <section>
              <h3>Chapter 4 本通知の更新</h3>
              <p>メタモは、本通知の構成、内容を更新することがあります。更新後は、更新前バージョンの本通知内のリンクまたはアクセス機能を通じて、過去の本通知の内容を知ることができます。</p>
              <p>欧州連合内のユーザーの個人データを処理する場合、<a href="//ec.europa.eu/info/law/law-topic/data-protection_en" target="_blank">GDPR（EU - General Data Protection Regulation）<BlankIcon /></a>が適用されます。この項目では、GDPRの規制に基づく欧州ユーザーの権利および当社の責任に関する情報を提供します。欧州から当ウェブサイトにアクセスする利用者にとっては特に大切なことですので十分にお読み頂き、ご理解して頂いた上で、ご承諾または拒否の判断を行ってください。</p>
            </section>
          </Reading>
        </div>
      </section>
      <LinkList>
        <li>
          <LinkBox
            :path = "{name: 'About'}"
          />
        </li>
        <li>
          <LinkBox
            :path = "{name: 'Company'}"
          />
        </li>
      </LinkList>
    </Main>
    <Footer />
  </div>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'

  import Header from '@/components/essentials/Header.vue'
  import Main from '@/components/essentials/Main.vue'
  import Footer from '@/components/essentials/Footer.vue'

  import Heading from '@/components/common/PageHeading.vue'
  import LeadText from '@/components/common/LeadText.vue'
  import LinkList from '@/components/common/LinkList.vue'
  import LinkBox from '@/components/common/LinkBox.vue'

  import Reading from '@/components/common/ReadingContent.vue'
  import BlankIcon from '@/assets/images/common/icon_launch.svg'

  export default {
    name: 'PrivacyNotice',
    components: {
      Header,
      Main,
      Footer,
      Heading,
      LeadText,
      LinkList,
      LinkBox,
      Reading,
      BlankIcon,
    },
    data () {
      return {
        SiteInfo: SiteInfo,
        pageTitle: 'プライバシーポリシー',
        description: 'メタモ株式会社のプライバシーポリシーをご覧いただけます。',
      }
    },
    created () {
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    head: {
      title () {
        return {
          inner: this.pageTitle,
        }
      },
      meta () {
        return this.defaultHead('meta', [
          { name: 'description', content: this.description },
          { property: 'og:title', content: this.pageTitle + ' | ' + this.SiteInfo.titleShort },
          { property: 'og:description', content: this.description },
          { property: 'og:url', content: this.SiteInfo.baseURL + location.pathname },
        ])
      },
      link () {
        return this.defaultHead('link', [
          { rel: 'canonical', href: this.SiteInfo.baseURL + location.pathname },
        ])
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .lead-text {
    margin-bottom: 50px;
  }
</style>
