<template>
  <div class="cletter">
    <div class="wrap">
      <section>
        <h3 class="cletter-heading">
          人間を自由にする<wbr>データテクノロジー
          <small>connecting the dots.</small>
        </h3>
        <LeadText>
          <p>
          私たちは信じています。テクノロジーが持つ力を。<br>
          そして、人々を豊かにするものであると。
          </p>
          <p>
          世界中の人々は夢を描きました。<br>
          テクノロジーが人々を豊かにする未来を。
          </p>
          <p>
          しかし、私たちは疑問に思うのです。<br>
          テクノロジーは人々の『心』を豊かにしてきたのだろうかと。<br>
          そして、未来も『心』を豊かにするもので<br>
          在り続けることができるのだろうかと。
          </p>
          <p>
          もちろん、過剰な心配は必要ありません。<br>
          テクノロジーが社会を進歩させることは明白です。<br>
          特に、直近30年で起きた技術革新と<br>
          それによって人類が得た成果は素晴らしいものでした。
          </p>
          <p>
          しかし、私たちは思うのです。<br>
          人類はそのスピードに取り残されているのではないかと。
          </p>
          <p>
          答えは過去――歴史にありました。
          </p>
          <p>
          人間を自由にする技芸であるリベラルアーツ。<br>
          体系上、リベラルアーツと切り離すことができないテクノロジー。<br>
          この考え方に立ち戻る必要があると考えたのです。
          </p>
          <p>
          テクノロジーが人間を不自由にしているのではないか。<br>
          私たちは、各所から聞こえる警鐘に耳を傾けずにはいられません。<br>
          しかし、私たちは非営利団体ではありません。<br>
          私たちは「エンジニアであるから」行動するのです。
          </p>
          <img :src="require('@/assets/images/about/about_cletter_img01.jpg')" alt="">
          <p>
          人類は古代ギリシア時代……紀元前7世紀には<br>
          自らを自由にする技芸を獲得していました。<br>
          しかし、どうやら最近は人間がテクノロジーで不自由になっている。<br>
          我々、人類は退化しているのでしょうか。
          </p>
          <p>
          いいえ、それは違うと断言します。<br>
          技術（テクノロジー）を正しく使う者達が<br>
          21世紀を代表するリーダーになり、<br>
          社会はおろか、文明を彩り、生き残るのです。
          </p>
          <p>
          そして、私たち「メタモ」はその一人です。<br>
          </p>
          <p>
          メタモは声高らかに謳います。<br>
          人類を進化させる技術の使い方を。<br>
          これからは、競争のある協奏が求められる時代であると<br>
          心の底から信じています。
          </p>
          <p>
          それが得意なメタモは世界を変えられるのです。<br>
          紀元前から紡がれてきた歴史と文明という賜物に対して、<br>
          短絡的な技術の使い方など通用しないのです。<br>
          それに異変を感じるからこそ、人々は不安を覚え、<br>
          声を出し、動き始めています。
          </p>
          <p>
          社会が歪めば、人類が歩み紡いできた文明を破壊しかねない。<br>
          私たちは歴史からそのことを幾度も学びました。
          </p>
          <p>
          文明は人々の衣食住を豊かにし、<br>
          人間としての品位を高めることである。<br>
          つまり、人間の知徳の進歩こそが文明であると考えます。
          </p>
          <p>
          私たちは、人類を進歩させるために<br>
          人間を自由にするテクノロジーを発信してゆきます。<br>
          リベラルアーツとテクノロジーが交わる、その交差点で。
          </p>
        </LeadText>
      </section>
    </div>
  </div>
</template>

<script>
  import LeadText from '@/components/common/LeadText.vue'

  export default {
    name: 'AboutCletter',
    components: {
      LeadText,
    },
    data () {
      return {
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .cletter {
    padding: 35px 0 50px;

    @media #{$device-l} {
      padding-top: 70px;
      padding-bottom: 200px;
    }

    &-heading {
      $margin: 20px;

      margin: 0 -#{$margin} 40px;
      line-height: 1.5;
      text-align: center;
      font-family: $ff-serif;
      font-size: 2.4rem;
      font-weight: bold;
      letter-spacing: 0.025em;
      white-space: nowrap;

      @media #{$device-l} {
        margin: 0 auto 100px;
        font-size: 4.4rem;
      }

      >small {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 $margin;
        margin-top: 15px;
        line-height: 1.0;
        text-align: center;
        font-family: $ff;
        font-size: 1.0rem;
        font-weight: normal;

        @media #{$device-l} {
          margin-top: 20px;
          font-size: 1.8rem;
        }

        &::before,
        &::after {
          content: "";
          flex: 1px 60px auto;
          height: 1px;
          width: 100%;
          max-width: 60px;
          margin: 0 15px;
          background-color: #626262;

          @media #{$device-l} {
            max-width: 150px;
          }

          @media #{$device-dark} {
            background-color: map-get($color-dark, "text");
          }
        }

        &::before {
          margin-left: 0;
        }

        &::after {
          margin-right: 0;
        }
      }
    }

    .lead-text {
      @media #{$device-s} {
        text-align: left;
      }

      p {
        @media #{$device-s} {
          width: 100%;
        }
      }
    }
  }
</style>
