<template>
  <div class="news-post">
    <article>
      <h2 class="news-post-title">
        {{ post.title }}
        <small v-if="post.subTitle">{{post.subTitle}}</small>
      </h2>
      <div class="news-post-information">
        <NewsCategory :category-id="post.category.id" />
        <NewsDate :newsDate="post.dateTime" />
      </div>
      <div
        v-if="post.pdf"
        class="news-post-pdf"
      >
        <a :href="post.pdf.url" target="_blank">
          PDFダウンロード
          <PDFIcon />
        </a>
      </div>
      <div id="post-body" class="news-post-body" v-html="post.body">
      </div>
    </article>
    <div class="news-other">
      <p
        class="news-other-button is-prev"
        :class="{'is-disable': !aroundPost.prev}"
      >
        <router-link
          v-if="aroundPost.prev"
          :to= "{
            name: 'NewsPost',
            params: {
              categoryId: aroundPost.prev.categoryId,
              postId: aroundPost.prev.id,
            },
          }"
          class="news-other-button-inner"
        >
          前のページへ
        </router-link>
        <span v-else class="news-other-button-inner">前のページへ</span>
      </p>
      <p class="news-other-button">
        <router-link
          :to="{name: 'NewsList'}"
          class="news-other-button-inner"
        >
          ニュース一覧
        </router-link>
      </p>
      <p
        class="news-other-button is-next"
        :class="{'is-disable': !aroundPost.next}"
      >
        <router-link
          v-if="aroundPost.next"
          :to= "{
            name: 'NewsPost',
            params: {
              categoryId: aroundPost.next.categoryId,
              postId: aroundPost.next.id,
            },
          }"
          class="news-other-button-inner"
        >
          次のページへ
        </router-link>
        <span v-else class="news-other-button-inner">次のページへ</span>
      </p>
    </div>
  </div>
</template>

<script>
  import NewsCategory from '@/components/common/NewsCategory.vue'
  import NewsDate from '@/components/common/NewsDate.vue'
  import PDFIcon from '@/assets/images/common/icon_pdf.svg'

  export default {
    name: 'NewsPickUp',
    components: {
      NewsCategory,
      NewsDate,
      PDFIcon,
    },
    props: {
      post: Object,
      aroundPost: Object,
    },
    data () {
      return {
      }
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  $border-style: 1px solid #ABC8C9;

  .news {
    &-post {
      margin: 100px auto 0;

      @media #{$device-l} {
        margin-top: 140px;
        max-width: 750px;
      }

      >article {
        display: flex;
        flex-direction: column;
      }

      &-title {
        padding-bottom: 25px;
        border-bottom: $border-style;
        margin-top: 10px;
        line-height: 1.5;
        font-size: 2.8rem;
        color: #626262;
        letter-spacing: 0.08em;

        @media #{$device-l} {
          padding-bottom: 20px;
          margin-top: 5px;
          line-height: 2.0;
          font-size: 3.2rem;
        }

        @media #{$device-dark} {
          color: inherit;
        }

        >small {
          display: block;
          margin-top: 8px;
          font-size: 1.6rem;
          color: #888888;
          letter-spacing: 0.05em;

          @media #{$device-l} {
            margin-top: 0;
            line-height: 1.3;
            font-size: 2.2rem;
          }

          @media #{$device-dark} {
            color: inherit;
          }
        }
      }

      &-information {
        order: -1;
        display: flex;
        align-items: center;
        padding-top: 25px;
        border-top: $border-style;

        .news-date {
          margin-left: 10px;
          color: #626262;

          @media #{$device-l} {
            margin-left: 15px;
          }

          @media #{$device-dark} {
            color: inherit;
          }
        }
      }

      &-pdf {
        margin-top: 10px;
        margin-left: auto;
        font-size: 1.0rem;
        color: #707070;

        @media #{$device-l} {
          margin-top: 20px;
        }

        @media #{$device-dark} {
          color: inherit;
        }

        >a {
          display: flex;
          align-items: center;
          padding: 5px 0;
          color: inherit;
          text-decoration: none;

          @media #{$device-l} {
            transition-duration: $duration;

            &:hover {
              opacity: $alpha;
            }
          }

          svg {
            margin-left: 8px;

            @media #{$device-l} {
              margin-left: 10px;
            }
          }
        }
      }

      &-body {
        margin-top: 25px;
        color: #626262;
        letter-spacing: 0.05em;

        @media #{$device-l} {
          font-size: 1.8rem;
        }

        @media #{$device-dark} {
          color: inherit;
        }

        ::v-deep {

          >* {
            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          h3,
          h4,
          h5,
          h6 {
            display: block;
            margin: 20px 0 10px;
            line-height: 1.5;
            font-weight: bold;

            &+h4,
            &+h5,
            &+h6,
            &+p {
              margin-top: 10px;
            }

            span {
              margin-left: 0.5em;
            }
          }

          h3 {
            width: 100%;
            padding-bottom: 10px;
            border-bottom: 2px solid #58AFB2;
            margin-top: 50px;
            margin-bottom: 20px;
            line-height: 1.5;
            font-size: 2.2rem;
          }

          h4 {
            margin-top: 30px;
            font-size: 2.2rem;
          }

          h5 {
            font-size: 2.0rem;
          }

          h6 {
            margin-bottom: 10px;
          }

          p {
            margin: 20px 0;
            line-height: 2.0;

            @media #{$device-l} {
              margin: 25px 0;
            }

            &.is-bold {
              margin-bottom: 10px;

              &+p {
                margin-top: 10px;
              }
            }
          }

          strong {
            font-weight: bold;
          }

          em {
            font-style: oblique;
          }

          u {
            text-decoration: underline;
          }

          s {
            text-decoration: line-through;
          }

          small {
            display: inline-block;
            font-size: 1.0rem;
            color: #888888;

            @media #{$device-l} {
              font-size: 1.6rem;
            }
          }

          sup {
            vertical-align: super;
            font-size: 0.9em;
          }

          a {
            color: map-get($color-brand, "accent");

            @media #{$device-l} {
              &:hover {
                text-decoration: none;
              }
            }

            img {
              display: inline-block !important;
              padding-right: 2px;
              padding-left: 3px;
              margin: 0;
            }
          }

          ul,
          ol {

            li {
              line-height: 2.0;

              &:first-child {
                margin-top: 0;
              }
            }
          }

          ul {
            $list-color: #28253C;

            li {
              position: relative;
              padding-left: 1em;
              margin-top: 5px;

              &::before {
                content: "";
                position: absolute;
                top: calc(1em - 3px);
                left: 2px;
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $list-color;

                @media #{$device-l} {
                }

                @media #{$device-dark} {
                  background-color: #fff;
                }
              }

              ul {
                margin-top: 5px;

                li {

                  &::before {
                    box-sizing: border-box;
                    border: 1px solid $list-color;
                    background-color: #fff;

                    @media #{$device-dark} {
                      border-color: #fff;
                      background-color: transparent;
                    }
                  }
                }
              }
            }
          }

          ol {
            counter-reset: number;

            li {
              counter-increment: number;
              margin-top: 20px;

              &::before {
                content: counter(number) ".";
              }
            }
          }

          blockquote {
            $color: #707070;
            $dark: #b9b9b9;

            position: relative;
            display: grid;
            grid-template-columns: 30px 1fr;
            grid-column-gap: 10px;
            grid-row-gap: 10px;
            padding: 20px 0;
            margin: 20px 0;
            line-height: 1.7;
            font-size: 1.4rem;
            color: $color;

            @media #{$device-s} {
              $padding: 20px;

              padding-top: $padding;
              padding-bottom: $padding;
            }

            @media #{$device-dark} {
              color: $dark;
            }

            &::after {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 220px;
              border: 1px solid $color;
              border-left: none;
              border-right: none;
              margin: auto 0;
              pointer-events: none;

              @media #{$device-s} {
                width: 30%;
                min-width: 150px;
                max-width: 220px;
              }

              @media #{$device-dark} {
                border-color: $dark;
              }
            }

            >sup {
              vertical-align: inherit;
              font-size: inherit !important;
            }
          }

          img {
            max-width: calc(50% - 5px);
            font-size: 0;
            cursor: pointer;

            @media #{$device-s} {
              display: block;
              max-width: 100%;
            }

            @media #{$device-l} {
            }

            &:nth-of-type(1):last-of-type {
              display: block;
              max-width: 100%;
              margin-right: auto;
              margin-left: auto;
            }

            &+br {
              display: none;
            }

            &~img {

              @media #{$device-s} {
                margin-top: 5px;
              }

              &:nth-of-type(2n+2) {
                margin-left: 10px;

                @media #{$device-s} {
                  margin-left: 0;
                }
              }
            }
          }

          figure {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            margin: 20px 0;

            @media #{$device-l} {
              margin: 40px 0;
            }

            figcaption {
              flex: 0 0 auto;
              width: 100%;
              margin-top: 10px;
              font-size: 1.2rem;
              line-height: 1.3;

              @media #{$device-l} {
                margin-top: 15px;
                font-size: 1.4rem;
              }

              br {
                display: none;
              }
            }

            img {
              flex: 0 0 auto;
              display: block;
              width: auto;
              max-width: calc(50% - 5px);
              height: auto;

              @media #{$device-s} {
                max-width: 100%;
              }

              &:only-of-type {
                max-width: 100%;
                max-height: inherit;
                margin: 10px auto 0;
              }

              &~img {
                margin-bottom: 0;

                @media #{$device-s} {
                  margin-top: 10px;
                }

                &:nth-child(n+3) {

                  @media #{$device-l} {
                    margin-top: 10px;
                  }
                }

                &:nth-of-type(odd) {
                  display: block;

                  &:last-of-type {
                    max-width: 100%;
                    margin: 10px auto 0;
                  }
                }
              }
            }
          }
        }
      }
    }

    &-other {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 0;

      @media #{$device-s} {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 20px));
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 30px;
        grid-row-gap: 20px;
        margin: 40px -30px 20px;
      }

      &-button {
        $arrow: 70deg;

        position: relative;
        font-size: 1.4rem;

        @media #{$device-l} {
          margin: 0 30px;
        }

        &-inner {
          display: block;
          overflow: hidden;
          padding: 15px 30px;
          color: inherit;
          white-space: nowrap;
          text-decoration: none;

          @media #{$device-l} {
            padding-right: 45px;
            padding-left: 45px;
            transition-duration: $duration;

            &:hover {
              opacity: $alpha;
            }
          }

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 7px;
            border-width: 0;
            border-bottom-width: 1px;
            border-style: solid;
            border-color: #707070;
            user-select: none;

            @media #{$device-dark} {
              border-color: #fff;
            }
          }
        }

        &:not([class*="is-"]) {
          margin: 0 40px;

          @media #{$device-s} {
            grid-column: 1 / -1;
            grid-row: 1 / 2;
            text-align: center;
            margin: 0 auto;
          }
        }

        &.is-prev,
        &.is-next {
          box-sizing: border-box;
          padding: 0;
          white-space: nowrap;

          @media #{$device-s} {
            width: 40vw;
          }

          @media #{$device-l} {
            width: auto;
          }

          .news-other-button-inner {
            display: block;
            padding: 15px 25px;
            text-align: center;

            @media #{$device-s} {
              padding-right: 10px;
              padding-left: 10px;
            }
          }
        }

        &.is-prev {
          margin-left: auto;

          .news-other-button-inner {
            padding-left: 40px;

            @media #{$device-s} {
              padding-left: 30px;
            }

            &::before {
              border-left-width: 3px;
              transform: skew($arrow * -1);
              transform-origin: bottom right;
            }
          }
        }

        &.is-next {
          margin-right: auto;

          .news-other-button-inner {
            padding-right: 40px;

            @media #{$device-s} {
              padding-right: 30px;
            }

            &::before {
              border-right-width: 3px;
              transform: skew($arrow);
              transform-origin: bottom left;
            }
          }
        }

        &.is-disable {
          opacity: 0.5;
          user-select: none;
        }
      }
    }
  }
</style>
