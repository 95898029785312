<template>
  <dl class="profile-table">
    <template
      v-for="(value, name) in profileDatas"
    >
      <dt :key="`label-${name}`">{{name}}</dt>
      <dd :key="`box-${name}`">
        <ul
          v-if="Array.isArray(value) && typeof value[0] === 'string'"
        >
          <li
            v-for="(childValue, index) in value"
            :key="index"
            v-html="childValue"
          ></li>
        </ul>
        <dl
          v-else-if="typeof value === 'object'"
        >
          <template
            v-for="child in value"
          >
            <template
              v-for="(childValue, childName) in child"
            >
              <dt :key="`child-label-${childValue}`">{{childName}}</dt>
              <dd :key="`child-box-${childValue}`">{{childValue}}</dd>
            </template>
          </template>
        </dl>
        <p
          v-else
          v-html="value"
        ></p>
      </dd>
    </template>
    <dt>お問合わせ</dt>
    <dd>
      ご連絡はメールアドレス :
      <a
        href="javascript: void(0)"
        @click="setClipBoardAlert(mail, 'メールアドレス')"
      >&#105;&#110;&#102;&#111;&#64;&#109;&#101;&#116;&#97;&#45;&#109;&#111;&#46;&#99;&#111;&#46;&#106;&#112;</a>
      からお願い致します
    </dd>
  </dl>
</template>

<script>
  import Datas from '@/assets/json/siteinfo.json'

  export default {
    name: 'commonProfileTable',
    components: {
    },
    data () {
      return {
        mail: Datas.mail,
        profileDatas: Datas.profile,
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
</style>
