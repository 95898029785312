<template>
  <div class="pager">
    <p class="pager-label">
      <span class="pager-label-active">
        {{ ($route.params.page)? nowPageCount : 1 }}
      </span>
      <span>{{ maxPageCount }}</span>
    </p>
    <div class="pager-button-list">
      <PagerButton
        around-class="prev"
        :is-disable="(pager.prev > 0)? false : true"
        :path="(pager.prev > 1)?
          {
            name: 'NewsListPage',
            params: {
              'page': pager.prev,
              'category': (category)? category : false,
            },
          }
          : {
            name: (!category)? 'NewsList' : 'NewsCategoryList',
            params: { category: category },
          }
        "
      />
      <ul class="pager-list">
        <li
          :class="{'tb-pc': nowPageCount>=5 || nowPageCount-1>1 }"
        >
          <PagerButton
            :is-active="(nowPageCount == 1)? true : false"
            :number="1"
            :path="{
              name: (!category)? 'NewsList' : 'NewsCategoryList',
              params: { category: category },
            }"
          />
        </li>
        <li
          v-if="pager.leftEllipsis"
          class="tb-pc"
        >
          <PagerButton />
        </li>
        <template v-for="index in pager.end">
          <li
            v-if="index>=pager.start && index>1 && index<maxPageCount"
            :class="{'tb-pc': (pager.prev<1&&index>pager.start+2) || (pager.next<1&&index<pager.end-2) || pager.prev>=1&&pager.next>=1&&(index<pager.prev||index>pager.next)
            }"
            :key="index"
          >
            <PagerButton
              :is-active="(index == nowPageCount)? true : false"
              :number="index"
              :path="{
                name: (!category)? 'NewsListPage' : 'NewsCategoryListPage',
                params: {
                  'page': index,
                  'category': category,
                },
              }"
            />
          </li>
        </template>
        <li
          v-if="pager.rightEllipsis"
          class="tb-pc"
        >
          <PagerButton />
        </li>
        <li :class="{'tb-pc': nowPageCount<=maxPageCount-2 }">
          <PagerButton
            :is-active="(nowPageCount >= maxPageCount)? true : false"
            :number="maxPageCount"
            :path="{
              name: (!category)? 'NewsListPage' : 'NewsCategoryListPage',
              params: {
                'page': maxPageCount,
                'category': category,
              },
            }"
          />
        </li>
      </ul>
      <PagerButton
        around-class="next"
        :is-disable="(pager.next > 0)? false : true"
        :path="{
          name: (!category)? 'NewsListPage' : 'NewsCategoryListPage',
          params: {
            'page': pager.next,
            'category': (category)? category : false,
          },
        }"
      />
    </div>
  </div>
</template>

<script>
  import PagerButton from '@/components/common/PagerButton.vue'

  export default {
    name: 'Pager',
    props: {
      nowPageCount: Number,
      maxPageCount: Number,
      category: {
        type: [String, Boolean],
        default: false,
      },
      maxLimit: {
        type: Number,
        default: 8,
      },
      startFix: {
        type: Number,
        default: 4,
      },
      endFix: {
        type: Number,
        default: 3, // startFix - 1
      },
    },
    components: {
      PagerButton,
    },
    data () {
      return {
        pager: {},
      }
    },
    created () {
      this.pager = this.setPager(this.maxPageCount, this.nowPageCount)
    },
    watch: {
      nowPageCount: {
        handler: function () {
          this.pager = this.setPager(this.maxPageCount, this.nowPageCount)
        },
        deep: true,
      },
    },
    methods: {
      setPager(max, now) {
        let result = {}

        if (max < this.maxLimit) {
          result = {
            start: 1,
            end: max,
            prev: (now > 1) ? now - 1 : 0,
            next: (now < max) ? now + 1 : 0,
            leftEllipsis: false,
            rightEllipsis: false,
          }
        } else {
          if (now <= this.startFix) {
            result = {
              start: 1,
              end: this.startFix + 1,
              prev: now - 1,
              next: now + 1,
              leftEllipsis: false,
              rightEllipsis: true,
            }
          } else if (now > this.startFix && now < max - this.endFix) {
            result = {
              start: now - 1,
              end: now + 1,
              prev: now - 1,
              next: now + 1,
              leftEllipsis: true,
              rightEllipsis: true,
            }
          } else {
            result = {
              start: max - this.endFix - 1,
              end: max,
              prev: now - 1,
              next: (now < max) ? now + 1 : 0,
              leftEllipsis: true,
              rightEllipsis: false,
            }
          }
        }

        return result
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .pager {

    &-label {
      $color: #626262;

      display: none;
      font-size: 1.4rem;
      color: $color;
      margin: 50px 0 15px;

      @media #{$device-s} {
        display: block;
      }

      >span {
        display: inline-block;
      }

      &-active {
        position: relative;
        padding-right: 10px;
        margin-right: 9px;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          display: block;
          width: 1px;
          height: 10px;
          margin: auto 0;
          background-color: $color;
          transform-origin: center center;
          transform: rotate(25deg);
        }
      }
    }

    &-button {
      &-list {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;

        @media #{$device-s} {
          box-sizing: border-box;
          justify-content: space-between;
          width: 100%;
          margin: 0 auto;
        }
      }
    }

    &-list {
      display: inherit;
      margin: 0 10px;

      @media #{$device-s} {
        justify-content: space-around;
        width: calc(100% - #{(44px + 5px) * 2});
        margin: 0 5px;
      }

      >li {
        margin-left: 10px;

        @media #{$device-s} {
          margin-left: 0;
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
</style>
