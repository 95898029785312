<template>
  <div
    class="contact"
    :style="{'background-image': 'url('+require('@/assets/images/home/home_contact_bg.jpg')+')'}"
  >
    <div class="contact-inner">
      <div class="wrap">
        <section>
          <h3 class="contact-heading">Careers</h3>
          <p class="contact-text">
            データやA.I.は人間を縛るためのモノではありません<br>
            人間を自由にするデータテクノロジーカンパニーのメタモで<br>
            一緒に働きませんか？
          </p>
          <p class="contact-button">
            <Button
              text="採用サイトへ"
              color-class="white"
              :path="SiteInfo.careerURL"
            />
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'

  import Button from '@/components/common/LinkButton.vue'

  export default {
    name: 'HomeContact',
    components: {
      Button,
    },
    data () {
      return {
        SiteInfo: SiteInfo,
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .contact {
    position: relative;
    overflow: hidden;
    height: auto;
    max-height: 700px;
    text-align: right;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left 60% center;

    @media #{$device-l} {
      max-height: 1080px;
      background-position: center bottom;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 694px / 417px * 100%;
      background-color: #061C0D;
      opacity: 0.3;

      @media #{$device-l} {
        padding-top: 1080px / 1500px * 100%;
        opacity: 1;
        background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 20%, rgba(0,0,0,0.45) 100%);
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0.45) 100%);
        background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 20%,rgba(0,0,0,0.45) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#73000000',GradientType=0 );
      }

      @media #{$device-dark} {
        opacity: 0.5;
        background-color: rgb(30, 15, 50);
      }
    }

    .wrap {
      @media #{$device-l} {
        width: calc(100% - #{200px - 60px});
        min-width: 960px;
        max-width: $pc-max-width;
      }
    }

    &-inner {
      box-sizing: border-box;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      margin: auto;

      @media #{$device-l} {
      }

      &::after {
        flex: 0 0 auto;
        content: "";
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 100px;

        @media #{$device-l} {
          height: 240px / 1080px * 100%;
          max-height: 240px;
        }
      }
    }

    &-heading {
      margin-bottom: 40px;
      font-size: 5.4rem;
      font-weight: bold;

      @media #{$device-l} {
        margin-bottom: 30px;
        font-size: 8.4rem;
        letter-spacing: -0.05em;
      }
    }

    &-text {
      position: relative;
      z-index: 1;
      margin: 40px 0;
      line-height: 2.0;

      @media #{$device-l} {
        margin: 30px 0;
      }
    }

    &-button {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: flex-end;

      @media #{$device-s} {
        justify-content: center;
      }
    }

    &-image {
      box-sizing: border-box;
      width: calc(100% - 150px);
      max-width: 200px;
      margin-top: calc(-10% - 50px);
      margin-left: auto;
      background-repeat: no-repeat;
      background-position: top 56% left 35%;
      background-size: 200% auto;
      user-select: none;

      @media #{$device-l} {
        width: 350px;
        max-width: unset;
        margin-top: -270px;
        margin-right: -30px;
      }

      &::before {
        content: "";
        display: block;
        padding-top: 125%;
      }
    }
  }
</style>
