<template>
  <div class="link-area">
    <aside>
      <div
        :is="'h'+level"
        class="link-area-heading"
      >{{heading}}</div>
      <div class="wrap">
        <ul class="link-area-list">
          <slot />
        </ul>
      </div>
    </aside>
  </div>
</template>

<script>
  export default {
    name: 'LinkList',
    components: {
    },
    props: {
      level: {
        type: String,
        default: "3"
      },
      heading: {
        type: String,
        default: "メタモについてもっと知りたい方はこちら"
      },
    },
    data () {
      return {
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .link-area {
    padding: 70px 0;

    @media #{$device-l} {
      padding: 70px 0 55px;
    }

    &-heading {
      margin-bottom: 30px;
      line-height: 1.5;
      text-align: center;
      color: #626262;
      letter-spacing: 0.05em;

      @media #{$device-l} {
        font-size: 2.0rem;
      }

      @media #{$device-dark} {
        color: map-get($color-dark, "text");
      }
    }

    &-list {
      $margin: 20px;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: $margin * (-0.5);

      @media #{$device-l} {
      }

      >li {
        flex: 0 0 auto;
        margin: $margin / 2;
        width: calc(50% - #{$margin});
        max-width: 480px;

        @media #{$device-s} {
          max-width: inherit;
          width: 100%;
        }

        @media #{$device-l} {
        }
      }
    }

    /deep/ .link-box {
      width: 100%;

      &::before {
        padding-top: 132px / 370px * 100%;

        @media #{$device-l} {
          padding-top: 160px;
        }
      }
    }
  }
</style>
