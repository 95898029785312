<template>
  <div class="l-container">
    <Header />
    <Main>
      <section>
        <Hero>
          <Heading
            v-if="!$route.params.category"
            :jp-text = pageTitle
            en-text = "NewsRoom"
          />
          <Heading
            v-else
            :jp-text = pageTitle
            :en-text = "'NewsRoom:'+categories[categories.findIndex(active => active.id === $route.params.category)].name"
          />
        </Hero>
        <PickUp
          v-if="!$route.params.category && !$route.params.page"
          :post = "pickUpPost"
        />
        <div class="wrap">
          <div class="news-list-head">
            <Categories
              :active-category="($route.params.category) ? $route.params.category : false"
            />
            <p
              v-if="$route.params.category"
              class="news-list-back sp"
            >
              <router-link
                :to="{name: 'NewsList'}"
              >
                ニュース一覧へ
              </router-link>
            </p>
          </div>
          <ul class="news-list">
            <template
              v-for="newsIndex in displayCount"
            >
              <li
                v-if="(nowPageCount-1)*displayCount+newsIndex-1 < newsList.length"
                :key="newsIndex"
              >
                <div class="news">
                  <router-link
                    class="news-image"
                    :to= "{
                      name: 'NewsPost',
                      params: {
                        categoryId: newsList[(nowPageCount-1)*displayCount+newsIndex-1].category.id,
                        postId: newsList[(nowPageCount-1)*displayCount+newsIndex-1].id,
                      },
                    }"
                  >
                    <div class="news-image-inner">
                      <img v-if="newsList[(nowPageCount-1)*displayCount+newsIndex-1].thumbnail" :src="newsList[(nowPageCount-1)*displayCount+newsIndex-1].thumbnail.url" alt="" />
                      <template v-else>
                        <img class="is-light" :src="require('@/assets/images/news/thum_light.jpg')" alt="" />
                        <img class="is-dark" :src="require('@/assets/images/news/thum_dark.jpg')" alt="" />
                      </template>
                    </div>
                  </router-link>
                  <div class="news-about">
                    <div class="news-information">
                      <NewsDate :newsDate="newsList[(nowPageCount-1)*displayCount+newsIndex-1].dateTime" />
                      <NewsCategory :category-id="newsList[(nowPageCount-1)*displayCount+newsIndex-1].category.id" />
                    </div>
                    <router-link
                      class="news-post"
                      :to= "{
                        name: 'NewsPost',
                        params: {
                          categoryId: newsList[(nowPageCount-1)*displayCount+newsIndex-1].category.id,
                          postId: newsList[(nowPageCount-1)*displayCount+newsIndex-1].id,
                        },
                      }"
                    >
                      <h3 class="news-title" v-html="$sanitize(setTextEscape(newsList[(nowPageCount-1)*displayCount+newsIndex-1].title))">
                      </h3>
                      <p class="news-text" v-html="$sanitize(setTextEscape(newsList[(nowPageCount-1)*displayCount+newsIndex-1].body))">
                      </p>
                    </router-link>
                  </div>
                </div>
              </li>
            </template>
          </ul>
          <Pager
            v-if="newsList.length > displayCount"
            :nowPageCount = "($route.params.page)? Number($route.params.page) : 1"
            :maxPageCount = "maxPageCount"
            :category = "($route.params.category)? $route.params.category : false"
          />
        </div>
      </section>
      <LinkList>
        <li>
          <LinkBox
            :path = "{name: 'Founder'}"
          />
        </li>
        <li>
          <LinkBox
            :path = "{name: 'Company'}"
          />
        </li>
      </LinkList>
    </Main>
    <Footer />
  </div>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'
  import NewsPosts from '@/jsons/news.json'
  import NewsCategoryList from '@/jsons/newsCategories.json'

  import Header from '@/components/essentials/Header.vue'
  import Main from '@/components/essentials/Main.vue'
  import Footer from '@/components/essentials/Footer.vue'

  import NewsCategory from '@/components/common/NewsCategory.vue'
  import NewsDate from '@/components/common/NewsDate.vue'

  import PickUp from '@/components/news/NewsPickUp.vue'
  import Categories from '@/components/news/NewsListCategories.vue'
  import Pager from '@/components/common/Pager.vue'

  import Hero from '@/components/common/Hero.vue'
  import Heading from '@/components/common/PageHeading.vue'
  import LinkList from '@/components/common/LinkList.vue'
  import LinkBox from '@/components/common/LinkBox.vue'

  export default {
    name: 'NewsList',
    components: {
      Header,
      Main,
      Footer,
      Hero,
      Heading,
      NewsCategory,
      NewsDate,
      PickUp,
      Categories,
      Pager,
      LinkList,
      LinkBox,
    },
    data () {
      return {
        SiteInfo: SiteInfo,
        pageTitle: 'ニュースルーム',
        description: '',
        newsList: [],
        categories: NewsCategoryList.contents,
        displayCount: 10,
        nowPageCount: 0,
        maxPageCount: 0,
        pickUpPost: {},
      }
    },
    created () {
      this.setNewsList()
      this.setNewsPickUp()
      this.setPageCount()
    },
    computed: {
    },
    watch: {
      '$route' (to, from) {
        if( to != from) {
          this.setNewsList()
          this.setNewsPickUp()
        }

        this.setPageCount()
      },
    },
    methods: {
      setNewsList () {
        this.newsList = []
        let activeNumber = this.$route.params.page ? Number(this.$route.params.page) : 1

        if( !this.$route.params.category ) {
          if( activeNumber<1 ) {
            this.$router.push({ name: "NewsList" })
          }
          else {
            this.newsList = JSON.parse(JSON.stringify(NewsPosts.contents))
          }
        }
        else {
          let activeCategory = this.$route.params.category

          let notFoundFlg = this.categories.some(function(element) {
            return (element.id == activeCategory)
          })

          if( !notFoundFlg || activeNumber<1 ) {
            this.$router.push({ name: "NewsList" })
          }
          else {
            this.newsList = NewsPosts.contents.filter(
              function(value, index, array) {
              if( array[index].category.id === activeCategory ) {
                return array[index]
              }
            })
          }
        }
      },
      setNewsPickUp () {
        if( !this.$route.params.category ) {
          let pickUpFlg = false

          for( let i=0 ; i < this.newsList.length ; i++ ) {

            if( this.newsList[i].pickUp ) {
              pickUpFlg = true
              this.pickUpPost = this.newsList[i]
              this.pickUpPost.title = this.setTextEscape(this.pickUpPost.title)
              this.pickUpPost.body = this.setTextEscape(this.pickUpPost.body)
              this.newsList.splice(i, 1)

              i = this.newsList.length
            }
          }

          if( !pickUpFlg ) {
            this.pickUpPost = this.newsList[0]
            this.pickUpPost.title = this.setTextEscape(this.pickUpPost.title)
            this.pickUpPost.body = this.setTextEscape(this.pickUpPost.body)
            this.newsList.splice(0, 1)
          }
        }
      },
      setPageCount () {
        this.maxPageCount = Math.ceil( this.newsList.length / this.displayCount )
        this.nowPageCount = ( this.$route.params.page )? this.$route.params.page : 1
      },
      setTextEscape (text) {
        return text.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')
      },
    },
    head: {
      title () {
        return {
          inner: this.pageTitle,
        }
      },
      meta () {
        return this.defaultHead('meta', [
          { property: 'og:title', content: this.pageTitle + ' | ' + this.SiteInfo.titleShort },
          { property: 'og:url', content: this.SiteInfo.baseURL + location.pathname },
        ])
      },
      link () {
        return this.defaultHead('link', [
          { rel: 'canonical', href: this.SiteInfo.baseURL + location.pathname },
        ])
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .hero {
    margin-bottom: 30px;
    background-image: url('~@/assets/images/news/news_hero.jpg');

    @media #{$device-s} {
      margin-bottom: 0;
    }

    ::v-deep {
      .heading {
        @media #{$device-m} {
          width: 60%;
        }

        @media #{$device-l} {
          width: auto;
          min-width: 540px;
        }
      }
    }
  }

  .news {
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      @media #{$device-s} {
        display: block;
      }

      @media #{$device-l} {
      }

      >li {
        flex: 0 0 auto;
        width: calc(50% - 15px);
        margin-top: 50px;

        @media #{$device-s} {
          width: 100%;
          margin-top: 30px;
        }

        &:first-child {
          margin-top: 0;
        }

        &:nth-child(-n+2) {
          margin-top: 0;

          @media #{$device-s} {
            margin-top: 50px;
          }
        }

        &:nth-child(even) {
          margin-left: 30px;

          @media #{$device-s} {
            margin-left: 0;
          }
        }
      }

      &-head {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin: 30px 0;

        @media #{$device-s} {
          flex-direction: column-reverse;
        }
      }

      &-back {
        $arrow: 70deg;

        box-sizing: border-box;
        position: relative;
        font-size: 1.4rem;
        white-space: nowrap;

        @media #{$device-s} {
          overflow: hidden;
          width: 100%;
          margin-bottom: 50px;
        }

        >a {
          display: block;
          padding: 15px 10px;
          padding-left: 35px;
          text-align: center;
          color: inherit;
          text-decoration: none;

          @media #{$device-l} {
            padding-right: 25px;
            padding-left: 40px;
          }

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 7px;
            border-width: 0;
            border-bottom-width: 1px;
            border-left-width: 3px;
            border-style: solid;
            border-color: #707070;
            transform: skew($arrow * -1);
            transform-origin: bottom right;
            user-select: none;

            @media #{$device-dark} {
              border-color: #fff;
            }
          }
        }
      }
    }

    display: block;
    overflow: hidden;

    &-image {
      position: relative;
      display: block;
      overflow: hidden;

      @media #{$device-l} {

        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }

      &::before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 304px / 477px * 100%;
      }

      &-inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        overflow: hidden;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media #{$device-l} {
          position: absolute;
          transform-origin: center center;
          transition-duration: $duration;
          backface-visibility: hidden;
        }
      }
    }

    &-about {
      margin: 15px;
      margin-bottom: 0 !important;

      @media #{$device-l} {
        margin: 20px;
      }
    }

    &-information {
      grid-area: information;
      display: flex;
      align-items: center;

      @media #{$device-l} {
      }

      &::before {
        content: "";
        display: block;
        width: 1px;
        height: 12px;
        margin-right: 14px;
        margin-left: 10px;
        background-color: map-get($color-text, "base");
        transform-origin: center center;
        transform: skew(-25deg);

        @media #{$device-dark} {
          background-color: #fff;
        }
      }

      .news {
        &-date {
          order: -1;
          font-size: 1.0rem;

          @media #{$device-l} {
            font-size: 1.3rem;
          }
        }

        &-category {
        }
      }
    }

    &-post {
      position: relative;
      display: block;
      overflow: hidden;
      color: map-get($color-text, "link");
      text-decoration: none;

      @media #{$device-l} {
        transition-duration: $duration;

        &:hover {
          opacity: 0.7;
        }
      }

      @media #{$device-dark} {
        color: inherit;
      }

      &::after {
        flex: 0 0 auto;
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        height: 20px;
        margin: 0 auto;
        @include gradientBottom;

        @media #{$device-dark} {
          @include gradientBottom(map-get($color-dark, "main"));
        }
      }
    }

    &-title {
      overflow: hidden;
      height: 2em * 1.5;
      margin: 2px 0 5px;
      line-height: 1.5;
      font-weight: bold;
      font-size: 1.8rem;

      @media #{$device-s} {
        height: auto;
        max-height: 3em * 1.5;
      }

      @media #{$device-l} {
        margin: 10px 0 5px;
        font-size: 2.0rem;
      }
    }

    &-text {
      display: block;
      overflow: hidden;
      height: 3em * 1.7;
      line-height: 1.7;
      font-size: 1.2rem;

      @media #{$device-s} {
      }
    }
  }
</style>
