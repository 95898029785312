<template>
  <router-link
    v-if="path && typeof path === 'object'"
    :to="path"
    class="button"
    :class="[setColorClass()]"
  >
    {{ text }}
  </router-link>

  <a
    v-else-if="path.match(/javascript/)"
    :href="path"
    class="button is-blank"
    :class="[setColorClass()]"
  >
    {{ text }}
    <BlankIcon />
  </a>

  <a
    v-else
    :href="path"
    class="button is-blank"
    :class="[setColorClass()]"
    target="_blank"
  >
    {{ text }}
    <BlankIcon />
  </a>
</template>

<script>
  import BlankIcon from '@/assets/images/common/icon_launch.svg'

  export default {
    name: 'LinkButton',
    components: {
      BlankIcon,
    },
    props: {
      path: {
        type: [String, Object],
        default: () => {}
      },
      text: String,
      colorClass: String,
    },
    data () {
      return {
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
      setColorClass () {
        if(this.colorClass) {
          return ['is-' + this.colorClass]
        } else {
          return []
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .button {
    $normal-color: #111;
    $hover-color: map-get($color-brand, "accent");

    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: auto;
    min-width: 100px;
    height: 40px;
    border: 1px solid $normal-color;
    padding: 0 20px;
    padding-right: 30px;
    line-height: 1.0;
    text-align: center;
    font-size: 1.2rem;
    color: $normal-color;
    text-decoration: none;
    letter-spacing: 0.1em;

    @media #{$device-dark} {
      border-color: map-get($color-dark, "text");
      color: map-get($color-dark, "text");
    }

    @media #{$device-l} {
      height: 42px;
      letter-spacing: 0.025em;
      transition-duration: $duration;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;

      @media #{$device-l} {
        transition-duration: $duration;
      }
    }

    &::before {
      z-index: -1;
      top: -10%;
      left: -10%;
      width: 0;
      height: 120%;
      transform: skewX(15deg);
      background-color: $hover-color;
    }

    &::after {
      $size: 5px;

      top: 2px;
      bottom: 0;
      right: 13px;
      width: $size;
      height: $size;
      border: 2px solid $normal-color;
      border-bottom: none;
      border-left: none;
      margin: auto 0;
      transform: rotate(45deg);
      transform-origin: center center;

      @media #{$device-l} {
        top: 0;
      }

      @media #{$device-dark} {
        border-color: map-get($color-dark, "text");
      }
    }

    &.is-blank {
      &::after {
        content: none;
      }

      svg {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto 0;

        &, path {
          stroke: $normal-color;

          @media #{$device-dark} {
            stroke: map-get($color-dark, "text");
          }

          @media #{$device-l} {
            transition-duration: $duration;
          }
        }
      }

      @media #{$device-l} {

        &:hover {
          svg {
            path {
              stroke: #fff;
            }
          }
        }
      }
    }

    &.is-white {
      border-color: #fff;
      color: #fff;

      &::after {
        border-color: #fff;
      }

      &.is-blank {
        svg {
          &, path {
            stroke: #fff;
          }
        }
      }
    }

    &.is-accent {
      border-color: $hover-color;
      color: #fff;

      &::before {
        width: 120%;
      }

      &.is-blank {
        svg {
          &, path {
            stroke: #fff;
          }
        }
      }

      @media #{$device-l} {
        &:hover {
          color: $hover-color;

          &::before {
            left: 120%;
          }

          &.is-blank {
            svg {
              &, path {
                stroke: $hover-color;
              }
            }
          }
        }
      }
    }

    @media #{$device-l} {
      &:hover {
        border-color: $hover-color;
        color: #fff;

        &::before {
          width: 120%;
        }

        &::after {
          border-color: #fff;
        }
      }
    }
  }
</style>
