<template>
  <div class="l-container">
    <Header />
    <Main>
      <section>
        <Hero
          size-class="medium"
        >
          <Heading
            :jp-text = pageTitle
            en-text = "About"
          />
        </Hero>
        <SideNav
          :navigation = navigation
          :fixed-position-ajustment="185"
        />
        <Cletter id="cletter" />
        <Member id="member" />
        <History id="history" />
      </section>
      <LinkList>
        <li>
          <LinkBox
            :path = "{name: 'Founder'}"
          />
        </li>
        <li>
          <LinkBox
            :path = "{name: 'Company'}"
          />
        </li>
      </LinkList>
    </Main>
    <Footer />
  </div>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'

  import Header from '@/components/essentials/Header.vue'
  import Main from '@/components/essentials/Main.vue'
  import Footer from '@/components/essentials/Footer.vue'

  import SideNav from '@/components/essentials/SideNavigation.vue'

  import Hero from '@/components/common/Hero.vue'
  import Heading from '@/components/common/PageHeading.vue'
  import LinkList from '@/components/common/LinkList.vue'
  import LinkBox from '@/components/common/LinkBox.vue'

  import Cletter from '@/components/about/AboutCletter.vue'
  import Member from '@/components/about/AboutMember.vue'
  import History from '@/components/about/AboutHistory.vue'

  export default {
    name: 'Metamo',
    components: {
      Header,
      Main,
      Footer,
      Hero,
      Heading,
      SideNav,
      LinkList,
      LinkBox,
      Cletter,
      Member,
      History,
    },
    data () {
      return {
        SiteInfo: SiteInfo,
        pageTitle: 'メタモについて',
        description: '人とデータの関係を再発明する。メタモではこのミッションのもと、新しい社会と価値観を創造するために、最高のメンバーと邁進しています。',
        navigation: [
          {
            "path": "cletter",
            "text": "メッセージ",
          },
          {
            "path": "member",
            "text": "役員紹介",
          },
          {
            "path": "history",
            "text": "メタモの歴史",
          },
        ],
      }
    },
    created () {
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    head: {
      title () {
        return {
          inner: this.pageTitle,
        }
      },
      meta () {
        return this.defaultHead('meta', [
          { name: 'description', content: this.description },
          { property: 'og:title', content: this.pageTitle + ' | ' + this.SiteInfo.titleShort },
          { property: 'og:description', content: this.description },
          { property: 'og:url', content: this.SiteInfo.baseURL + location.pathname },
        ])
      },
      link () {
        return this.defaultHead('link', [
          { rel: 'canonical', href: this.SiteInfo.baseURL + location.pathname },
        ])
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .hero {
    background-image: url('~@/assets/images/about/about_hero.jpg');

    @media #{$device-l} {
      max-height: 600px;
      margin-top: 65px;
    }

    @media #{$device-dark} {
      color:  #fff;
      background-image: url('~@/assets/images/about/about_hero_dark.jpg');
    }

    &::before {
      flex: 0 0 auto;
      content: "";
      display: block;
      width: 1px;
      height: 20vh;
      max-height: 80px;

      @media #{$device-s} {
        height: 30vh;
      }

      @media #{$device-l} {
        height: 26%;
        max-height: 26%;
      }
    }

    .heading {
      margin-top: 0;
      margin-bottom: 0;

      @media #{$device-m} {
        width: 50%;
      }


      ::v-deep >small {
        border-color: #454545;

        @media #{$device-dark} {
          border-color: #fff;
        }
      }
    }
  }
</style>
