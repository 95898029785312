var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-container"},[_c('Header'),_c('Main',[_c('section',[_c('Hero',[(!_vm.$route.params.category)?_c('Heading',{attrs:{"jp-text":_vm.pageTitle,"en-text":"NewsRoom"}}):_c('Heading',{attrs:{"jp-text":_vm.pageTitle,"en-text":'NewsRoom:'+_vm.categories[_vm.categories.findIndex(function (active) { return active.id === _vm.$route.params.category; })].name}})],1),(!_vm.$route.params.category && !_vm.$route.params.page)?_c('PickUp',{attrs:{"post":_vm.pickUpPost}}):_vm._e(),_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"news-list-head"},[_c('Categories',{attrs:{"active-category":(_vm.$route.params.category) ? _vm.$route.params.category : false}}),(_vm.$route.params.category)?_c('p',{staticClass:"news-list-back sp"},[_c('router-link',{attrs:{"to":{name: 'NewsList'}}},[_vm._v(" ニュース一覧へ ")])],1):_vm._e()],1),_c('ul',{staticClass:"news-list"},[_vm._l((_vm.displayCount),function(newsIndex){return [((_vm.nowPageCount-1)*_vm.displayCount+newsIndex-1 < _vm.newsList.length)?_c('li',{key:newsIndex},[_c('div',{staticClass:"news"},[_c('router-link',{staticClass:"news-image",attrs:{"to":{
                    name: 'NewsPost',
                    params: {
                      categoryId: _vm.newsList[(_vm.nowPageCount-1)*_vm.displayCount+newsIndex-1].category.id,
                      postId: _vm.newsList[(_vm.nowPageCount-1)*_vm.displayCount+newsIndex-1].id,
                    },
                  }}},[_c('div',{staticClass:"news-image-inner"},[(_vm.newsList[(_vm.nowPageCount-1)*_vm.displayCount+newsIndex-1].thumbnail)?_c('img',{attrs:{"src":_vm.newsList[(_vm.nowPageCount-1)*_vm.displayCount+newsIndex-1].thumbnail.url,"alt":""}}):[_c('img',{staticClass:"is-light",attrs:{"src":require('@/assets/images/news/thum_light.jpg'),"alt":""}}),_c('img',{staticClass:"is-dark",attrs:{"src":require('@/assets/images/news/thum_dark.jpg'),"alt":""}})]],2)]),_c('div',{staticClass:"news-about"},[_c('div',{staticClass:"news-information"},[_c('NewsDate',{attrs:{"newsDate":_vm.newsList[(_vm.nowPageCount-1)*_vm.displayCount+newsIndex-1].dateTime}}),_c('NewsCategory',{attrs:{"category-id":_vm.newsList[(_vm.nowPageCount-1)*_vm.displayCount+newsIndex-1].category.id}})],1),_c('router-link',{staticClass:"news-post",attrs:{"to":{
                      name: 'NewsPost',
                      params: {
                        categoryId: _vm.newsList[(_vm.nowPageCount-1)*_vm.displayCount+newsIndex-1].category.id,
                        postId: _vm.newsList[(_vm.nowPageCount-1)*_vm.displayCount+newsIndex-1].id,
                      },
                    }}},[_c('h3',{staticClass:"news-title",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.setTextEscape(_vm.newsList[(_vm.nowPageCount-1)*_vm.displayCount+newsIndex-1].title)))}}),_c('p',{staticClass:"news-text",domProps:{"innerHTML":_vm._s(_vm.$sanitize(_vm.setTextEscape(_vm.newsList[(_vm.nowPageCount-1)*_vm.displayCount+newsIndex-1].body)))}})])],1)],1)]):_vm._e()]})],2),(_vm.newsList.length > _vm.displayCount)?_c('Pager',{attrs:{"nowPageCount":(_vm.$route.params.page)? Number(_vm.$route.params.page) : 1,"maxPageCount":_vm.maxPageCount,"category":(_vm.$route.params.category)? _vm.$route.params.category : false}}):_vm._e()],1)],1),_c('LinkList',[_c('li',[_c('LinkBox',{attrs:{"path":{name: 'Founder'}}})],1),_c('li',[_c('LinkBox',{attrs:{"path":{name: 'Company'}}})],1)])],1),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }