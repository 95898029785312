<template>
  <div v-if="level"
    :is="'h'+level"
    class="heading"
    :class="[setSectionClass, setAlignClass, setColorClass]"
  >
    {{ enText }}
    <small>{{ jpText }}</small>
  </div>

  <span v-else
    class="heading"
    :class="[setSectionClass, setAlignClass, setColorClass]"
  >
    {{ enText }}
    <small>{{ jpText }}</small>
  </span>
</template>

<script>
  export default {
    name: 'PageHeading',
    components: {
    },
    props: {
      level: String,
      jpText: String,
      enText: String,
      sectionClass: String,
      alignClass: String,
      colorClass: String,
    },
    data () {
      return {
      }
    },
    computed: {
      setSectionClass () {
        if(this.sectionClass) {
          return ['is-' + this.sectionClass]
        } else {
          return []
        }
      },
      setAlignClass () {
        if(this.alignClass) {
          return ['is-' + this.alignClass]
        } else {
          return []
        }
      },
      setColorClass () {
        if(this.colorClass) {
          return ['is-' + this.colorClass]
        } else {
          return []
        }
      },
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style lang="scss">
  .heading-wrapper {
    display: block;
    text-align: center;
  }
</style>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .heading {
    flex: 0 0 auto;
    box-sizing: border-box;
    display: block;
    width: 100%;
    min-width: 300px;
    padding: 0 30px;
    margin: 60px+60px auto 50px;
    line-height: 1.5;
    text-align: center;
    font-size: 3.2rem;
    font-weight: bold;
    letter-spacing: 0.025em;
    color: inherit;

    @media #{$device-s} {
      padding: 0 15px;
    }

    @media #{$device-l} {
      width: 540px;
      padding: 0;
      margin-top: 85px + 100px;
      margin-bottom: 140px;
      font-size: 5.2rem;
    }

    >small {
      display: block;
      padding-top: 8px;
      border-top: 1px solid #727272;
      font-family: $ff;
      font-size: 1.0rem;
      font-weight: normal;
      letter-spacing: 0.1em;

      @media #{$device-l} {
        font-size: 1.6rem;
      }

      @media #{$device-dark} {
        border-color: map-get($color-dark, "text");
      }
    }

    @at-root h3 & {
      display: inline-block;
      width: auto;
      min-width: 0;
      padding: 0 30px;
      font-size: 3.1rem;

      @media #{$device-l} {
        font-size: 5.2rem;
      }

      >small {
        box-sizing: border-box;
        position: relative;
        right: -10px;
        left: -10px;
        width: calc(100% + 20px);
        padding: 10px;
        padding-bottom: 0;
        margin: 0 auto;
        font-size: 0.9rem;

        @media #{$device-l} {
          padding-top: 15px;
          font-size: 1.6rem;
        }
      }
    }

    &.is-content {
      margin-top: 100px;
      font-size: 2.8rem;
      font-weight: bold;
      letter-spacing: 0.025em;

      @media #{$device-l} {
        font-size: 3.8rem;
      }

      >small {
        padding-top: 0;
        border: none;
        margin-top: -2px;
        font-size: 1.0rem;
        letter-spacing: 0.05em;

        @media #{$device-l} {
          font-size: 1.2rem;
        }
      }
    }

    &.is-left {
      text-align: left;
    }

    &.is-right {
      text-align: left;
    }

    &.is-white {
      color: #fff;

      >small {
        border-color: #fff;
      }
    }
  }
</style>
