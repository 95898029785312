<template>
  <div class="l-container">
    <Header />
    <Main>
      <section>
        <Heading
          level = "2"
          :jp-text = "pageTitle"
          en-text = "Company"
        />
        <Table />
        <Map />
        <Office />
      </section>
      <LinkList>
        <li>
          <LinkBox
            :path = "{name: 'Founder'}"
          />
        </li>
        <li>
          <LinkBox
            :path = "{name: 'About'}"
          />
        </li>
      </LinkList>
    </Main>
    <Footer />
  </div>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'

  import Header from '@/components/essentials/Header.vue'
  import Main from '@/components/essentials/Main.vue'
  import Footer from '@/components/essentials/Footer.vue'

  import Heading from '@/components/common/PageHeading.vue'
  import LinkList from '@/components/common/LinkList.vue'
  import LinkBox from '@/components/common/LinkBox.vue'

  import Table from '@/components/company/CompanyTable.vue'
  import Map from '@/components/company/CompanyMap.vue'
  import Office from '@/components/company/CompanyOffice.vue'

  export default {
    name: 'Metamo',
    components: {
      Header,
      Main,
      Footer,
      Heading,
      LinkList,
      LinkBox,
      Table,
      Map,
      Office,
    },
    data () {
      return {
        SiteInfo: SiteInfo,
        pageTitle: '会社概要',
        description: 'メタモ株式会社の会社概要をご紹介します。',
      }
    },
    created () {
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    head: {
      title () {
        return {
          inner: this.pageTitle,
        }
      },
      meta () {
        return this.defaultHead('meta', [
          { name: 'description', content: this.description },
          { property: 'og:title', content: this.pageTitle + ' | ' + this.SiteInfo.titleShort },
          { property: 'og:description', content: this.description },
          { property: 'og:url', content: this.SiteInfo.baseURL + location.pathname },
        ])
      },
      link () {
        return this.defaultHead('link', [
          { rel: 'canonical', href: SiteInfo.baseURL + location.pathname },
        ])
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

</style>
