<template>
  <div class="pre-resource-loader">
    <img src="@/assets/images/about/about_hero.jpg" />
    <img src="@/assets/images/contact/contact_hero.jpg" />
    <img src="@/assets/images/home/hero_typo_bg.png" />
    <img src="@/assets/images/home/hero_typo_only.png" />
    <img src="@/assets/images/home/hero_mv.jpg" />
    <img src="@/assets/images/home/hero_mv_sp.jpg" />
    <img src="@/assets/json/siteinfo.json" />
  </div>
</template>

<script>
  export default { name: 'PreResourceLoader' }
</script>

<style scoped lang="scss">
  .pre-resource-loader {
    display: none;
  }
</style>
