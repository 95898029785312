<template>
  <footer>
    <div class="l-footer">
      <div class="wrap">
        <p class="site-logo">
          <a href="/">
            <Logo class="is-white" />
          </a>
        </p>
        <div class="site-navigation">
          <ul class="site-map">
            <li>
              <router-link :to="{name: 'Metamo'}">ホーム</router-link>
            </li>
            <li>
              <router-link :to="{name: 'About'}">メタモについて</router-link>
            </li>
            <li>
              <router-link :to="{name: 'Company'}">会社概要</router-link>
            </li>
            <li>
              <router-link :to="{name: 'Founder'}">創業者メッセージ</router-link>
            </li>
            <li>
              <router-link
                :to="{name: 'NewsList'}"
              >ニュースルーム</router-link>
            </li>
            <li>
              <router-link :to="{name: 'Contact'}">お問合わせ</router-link>
            </li>
          </ul>
          <div class="site-information">
            <ul class="site-information-list">
              <li>
                <a :href="SiteInfo.careerURL" target="_blank">
                  採用サイト
                  <BlankIcon />
                </a>
              </li>
              <li>
                <router-link :to="{name: 'PrivacyNotice'}">プライバシーポリシー</router-link>
              </li>
            </ul>
            <p class="copy-right">
              <small>Copyright &copy; 2020 Metamo Inc.</small>
            </p>
          </div>
        </div>
      </div>
      <PageScroll />
    </div>
  </footer>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'

  import Logo from '@/components/common/BrandLogo.vue'
  import BlankIcon from '@/assets/images/common/icon_launch.svg'
  import PageScroll from '@/components/essentials/PageScroll.vue'

  export default {
    name: 'Footer',
    components: {
      Logo,
      BlankIcon,
      PageScroll,
    },
    props: {
    },
    data () {
      return {
        SiteInfo: SiteInfo,
      }
    },
    computed: {
    },
    created () {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .l-footer {
    display: flex;
    padding: 40px 0 30px;
    color: #fff;
    background-color: map-get($color-brand, "dark");

    @media #{$device-l} {
      padding: 55px 0 75px;
    }

    &::before,
    &::after {
      @media #{$device-m} {
        flex: 0 0 auto;
        content: "";
        width: 5vw;
        min-width: 30px;
      }
    }

    .wrap {
      width: 90vw;
      max-width: calc(100vw - 60px);
      padding: 0;
      margin: 0 auto;

      @media #{$device-m} {
      }

      @media #{$device-l} {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        width: 80vw;
        min-width: 880px;
        max-width: 1400px;
      }
    }
  }

  .site {
    &-logo {
      margin-bottom: 30px;

      @media #{$device-l} {
        margin-right: 20px;
        margin-bottom: 0;
      }

      >a {
        display: inline-block;
        overflow: hidden;
        width: 150px;

        @media #{$device-l} {
          width: 200px;
        }
      }
    }

    &-navigation {
      font-size: 1.3rem;

      @media #{$device-l} {
        margin-top: 30px;
        font-size: 1.4rem;
      }
    }

    &-map {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: -25px;

      @media #{$device-s} {
        justify-content: space-between;
      }

      @media #{$device-l} {
        flex-wrap: nowrap;
        font-size: inherit;
        margin-top: 0;
      }

      >li {
        @media #{$device-s} {
          width: calc(50% - 10px);
        }

        display: inline-block;
        width: 10em;
        margin-top: 25px;

        @media #{$device-l} {
          display: flex;
          width: auto;
          margin-top: 0;
        }

        &::before {
          @media #{$device-l} {
            content: "";
            width: 3.25vw;
            min-width: 30px;
            max-width: 40px;
            height: 1px;
          }
        }

        &:first-child {
          &::before {
            @media #{$device-l} {
              content: none;
            }
          }
        }
      }

      a {
        color: inherit;
        text-decoration: none;

        @media #{$device-l} {
          white-space: nowrap;
          transition-duration: $duration;

          &:hover {
            opacity: $alpha;
          }
        }

        svg {
          margin-left: 2px;

          &, path {
            stroke: #fff;
          }
        }
      }

      &-label {
        margin-bottom: 15px;
        color: map-get($color-text, "gray");
      }

      &-child {
        >li {
          margin-top: 40px;

          @media #{$device-l} {
            margin-top: 15px;
          }

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }

    &-information {
      $color: #B9B9B9;

      padding-top: 20px;
      border-top: 1px solid #626262;
      margin-top: 40px;
      color: $color;

      @media #{$device-l} {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-top: 25px;
        margin-top: 30px;
      }

      &-list {

        @media #{$device-l} {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        >li {
          display: flex;
          margin-top: 10px;

          @media #{$device-l} {
            align-items: center;
            margin-top: 0;
          }

          &::before {
            @media #{$device-l} {
              content: "";
              display: block;
              width: 1px;
              height: 20px;
              border-left: 1px solid $color;
              margin: 0 14px;
            }
          }

          &:first-child {
            margin-top: 0;

            @media #{$device-l} {
              margin-left: 0;

              &::before {
                content: none;
              }
            }
          }
        }

        a {
          display: block;
          padding: 5px 0;
          color: inherit;
          text-decoration: none;

          @media #{$device-l} {
            padding: 0;
            transition-duration: $duration;

            &:hover {
              opacity: $alpha;
            }
          }

          svg {
            &, path {
              stroke: $color;
            }
          }
        }
      }
    }
  }

  .copy-right {
    margin-top: 45px;
    text-align: center;

    @media #{$device-l} {
      margin-top: 0;
    }

    >small {
      line-height: 1.0;
      font-size: inherit;

      @media #{$device-l} {
      }
    }
  }
</style>
