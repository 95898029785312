<template>
  <div class="history">
    <section>
      <h3 class="heading-wrapper">
        <Heading
          jp-text = "メタモの歴史"
          en-text = "History"
        />
      </h3>
      <div class="wrap">
        <ul class="history-list">
          <li
            v-for="(history, historyIndex) in historyDatas"
            :key="historyIndex"
          >
            <div
              class="history-box"
              :class="{'is-open': history.isOpen}"
            >
              <section>
                <h4
                  class="history-title"
                  v-on:click="history.isOpen=!history.isOpen"
                >{{history.title}}</h4>
                <div class="history-date">
                  {{ history.date }}
                </div>
                <div
                  class="history-text"
                  :class="{'is-open': history.isOpen}"
                >
                  <template
                    v-for="(text, textIndex) in history.text"
                  >
                    <img
                      v-if="text.match(/jpg|png|gif/)"
                      :src="text"
                      alt=""
                      :key="textIndex"
                    />
                    <p
                      v-else
                      v-html="text"
                      :key="textIndex"
                    ></p>
                  </template>
                </div>
              </section>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
  import Heading from '@/components/common/PageHeading.vue'

  export default {
    name: 'AboutMember',
    components: {
      Heading,
    },
    data () {
      return {
        historyDatas: [
          {
            "isOpen": false,
            "date": "2016年 春頃",
            "title": "過去のIT企業からの脱却",
            "text": [
              "ベビーシッターのマッチングを行なうWEBサービスを利用したときに感じた違和感。これがメタモの始まりでした。",
              "仕事を請け負う立場のワーカー（フリーランサー）が獲得した評価データがサービス自体に強く結びつき、エクスポートする仕組みがないことに疑問を持ったのです。",
              "メタモは「IT企業がパーソナルデータの所有者」であるという古いIT企業の考え方を捨て、生み出されたデータは個人の歴史そのものであると捉え、データが結びつく人物が自由に扱える世界が望ましいと考えるようになりました。",
            ]
          },
          {
            "isOpen": false,
            "date": "2016年 夏頃",
            "title": "最初から国外へ行くと決める",
            "text": [
              "国会図書館に通い論文等を読み漁り、メタモが解決すべき本当の課題（論点）を見つけ出した頃。",
              "日本との親和性の高さから「ベトナム」が Tier 1 の市場であると決まりました。代表の佐藤は「いずれは海外ではなく、最初から海外でサービスを提供すること」を創業二社目のマイルストーンにしていたのです。",
            ]
          },
          {
            "isOpen": false,
            "date": "2017年 初春",
            "title": "自分をアピールする新しい手段を提供",
            "text": [
              "ベトナムのホーチ・ミンシティ。町の市場近くにある看板をあえて選び、わずか半月だけ掲載されたメタモの看板。メタモの実験と検証はここから始まったのです。",
              require("@/assets/images/about/about_history_metamo_card.jpg"),
              "その後口コミだけで広がり、わずかな看板のみの施策で月間アクティブユーザー数が約6万人になるとはこの時は誰も想像していませんでした（2020年1Q現在）。<br><br>「時代はカードレス。逆行するのかい？」そういった見方をされることが多々ありました。しかし、私たちは確信していました。仮想ではなくフィジカル（物理的な）カードが醸し出す信頼感を。そして、それが Tier 1 の市場では必要であることを。",
            ]
          },
          {
            "isOpen": false,
            "date": "2017年3月",
            "title": "日本法人を設立",
            "text": [
              "検証と実験に明け暮れていた頃。日本での活動をしやすくするために「メタモ株式会社」を設立することになりました。",
              "多くのスタートアップ企業は「起業するぞ！」と準備するパターンも多いものですが、代表の佐藤は一社目と同様に「必要になったから法人化する」という流れになりました。",
              require("@/assets/images/about/about_history_metamo_start.jpg"),
              "ここから日本における事業活動も活発化して行きます。ちなみにこの時、共同創設者の妻は産後2ヶ月未満。育児・仕事の双方で大変な時期。奮起していたことを覚えています。<br><br>メタモの由来はドイツ語の「Metamorphose」から。適者生存する課題解決企業という哲学は、この頃から既に根付いていたのです。",
            ]
          },
          {
            "isOpen": false,
            "date": "2017年 秋頃",
            "title": "映像・画像解析の研究開発の取り組みを開始",
            "text": [
              "日々の様々な事柄を記録する上で課題となるのが入力方法。スマートフォンから文字入力をすることは煩雑であり、いずれかの未来においては出来る限り自動化できることが望ましいという結論に至ります。",
              "そこで、映像及び画像解析を通じてデータ入力が自動化されるようにするための研究開発に取り組むことにしました。",
              require("@/assets/images/about/about_history_imaging.jpg"),
              "もちろん、2017年当時から現在に至るまでプライバシーに対する不安が多く、音声入力ですら警戒されている状況ですが、エッジ端末の普及や個人情報トレーサビリティーの確立によって人々の意識が変化してゆくと予想しています。",
              "なにしろ、今では当たり前の「Googleストリートビュー」も登場してからしばらくは世間から嫌われていたのですから。",
            ]
          },
          {
            "isOpen": false,
            "date": "2018年3月",
            "title": "大胆な決断をする",
            "text": [
              "一昔前の表現を使うならば「ライフログ」のように機能する Metamo Sphere について、メタモは重大な決断を下しました。それは、それまで採用しようとしていたブロックチェーン技術を利用しないという決断を下したのです。その結果、いくつかのプロジェクトは閉鎖されました。",
              "相当に悩むものでしたが、私たちは自分たちの信念を曲げず、技術についての利用可否の判定を改めて行った上でそのような決断を下したのです。",
              "原則的に後から書き換え不能であるブロックチェーン技術を採用することは「人間らしさ」を人々が失うキッカケになりかねないと危惧したのです。誰にも間違いがあり、隠したい過去があっても良く、全てオープンである必要はない。なぜなら、人間だからです。",
              "であるからこそ、私たちは Metamo Sphere とブロックチェーン技術を決別させることにしました。テクノロジーの発展によって生活が息苦しく感じるようになることはメタモの理想とはかけ離れたものです。",
            ]
          },
          {
            "isOpen": false,
            "date": "2019年5月",
            "title": "日本法人をWeWork東急四谷に移転",
            "text": [
              "日本での開発および会社運営を本格化するために、本社（本店）を WeWork東急四谷 に移転しました。",
            ]
          },
          {
            "isOpen": false,
            "date": "2019年8月",
            "title": "日本法人のオフィスを六本木にも拡張",
            "text": [
              "さらなる体制強化のために WeWork東急四谷 と WeWorkアークヒルズサウス の二拠点体制に増床しました。",
            ]
          },
          {
            "isOpen": false,
            "date": "2019年9月",
            "title": "海外3拠点を開設して国外市場を本格化",
            "text": [
              "インドのムンバイ、マレーシアのクアラルンプール、ベトナムのホーチミンシティの三ヶ所に海外拠点を新たに開設。Tier 1 の市場における Metamo Suite の展開を強化するための大きな一歩となりました。",
            ]
          },
          {
            "isOpen": false,
            "date": "2019年12月",
            "title": "日本法人のオフィスをWeWork東急四谷に統合 20人弱の規模へ",
            "text": [
              "WeWork東急四谷で大幅増床を行い、六本木にあったオフィスも統合。効率的かつコミュニケーションを重視した組織のあり方を実現するために決断しました。",
            ]
          },
        ],
      }
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .history {
    $animation: 0.3s;
    $color: #53bcc0;

    $size: ("sp": 28px, "pc": 34px);
    $arrow: ("sp": 12px, "pc": 10px);

    display: block;
    overflow: hidden;
    padding: 50px 0;

    @media #{$device-l} {
      padding-top: 100px;
      padding-bottom: 70px;
    }

    .heading {
      margin-top: 0;

      @media #{$device-s} {
      }
    }

    &-list {

      @media #{$device-l} {
        width: 70vw;
        max-width: 850px;
        margin: 0 auto;
      }

      >li {
        position: relative;
        padding-top: 40px;
        padding-left: 30px;
        border-left: 3px solid #b9b9b9;

        @media #{$device-l} {
          padding-left: 40px;
        }

        @media #{$device-dark} {
          border-color: map-get($color-dark, "text");
        }

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #ededed;

          @media #{$device-dark} {
            background-color: #626262;
          }
        }

        &:first-child {
          padding-top: 0;
        }
      }
    }

    &-box {
      padding-bottom: 30px;
      color: #707070;
      transition-duration: $animation;

      @media #{$device-l} {
        padding-bottom: 20px;
        font-size: 1.6rem;
      }

      @media #{$device-dark} {
        color: map-get($color-dark, "text");
      }

      >section {
        display: flex;
        flex-direction: column;
      }

      &.is-open {
        padding-bottom: 60px;

        .history {
          &-title {

            &::before {
              transform: translateY(12px);
            }

            &::after {
              transform: translateY(5px + 12px) rotate(225deg);
            }
          }

          &-text {
            padding-top: 20px;
            opacity: 1;

            p {
              margin-top: 20px;
              line-height: inherit;
            }


            ::v-deep {
              img {
                width: 100%;
                margin-top: 20px;
                transform: scale(1.0);

                @media #{$device-l} {
                  width: 250px;
                }
              }
            }
          }
        }
      }
    }

    &-title {
      $right: ("sp": 10px, "pc": 10px);
      $bottom: ("sp": 32px, "pc": 20px);

      order: 2;
      padding-right: 50px;
      line-height: 1.5;
      color: $color;
      font-weight: bold;
      font-size: 2.0rem;
      cursor: pointer;

      @media #{$device-l} {
        font-size: 2.4rem;
        transition-duration: $duration;

        &:hover{
          opacity: .8;
        }
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        display: block;
        transition-duration: $animation;
      }

      &::before {
        right: map-get($right, "sp");
        bottom: map-get($bottom, "sp");
        width: map-get($size, "sp");
        height: map-get($size, "sp");
        background-color: $color;
        border-radius: 50%;
      }

      &::after {
        box-sizing: border-box;
        right: ( map-get($size, "sp") - map-get($arrow, "sp") ) / 2 + map-get($right, "sp");
        bottom: ( map-get($size, "sp") - map-get($arrow, "sp") ) / 2 + map-get($bottom, "sp") + 2px;
        width: map-get($arrow, "sp");
        height: map-get($arrow, "sp");
        border: 2px solid #fff;
        border-top: none;
        border-left: none;
        transform-origin: center center;
        transform: rotate(45deg);
      }
    }

    &-date {
      order: 1;
      margin-bottom: 15px;

      @media #{$device-l} {
      }

      &::before {
        content: "";
        position: absolute;
        left: -10.5px;
        display: block;
        width: 18px;
        height: 18px;
        margin-top: -5px;
        border-radius: 50%;
        background-color: #707070;

        @media #{$device-dark} {
          background-color: #fff;
        }
      }
    }

    &-text {
      order: 3;
      overflow: hidden;
      padding-top: 0;
      margin-right: 50px;
      line-height: 2.0;
      opacity: 0;
      transition-duration: $animation;

      @media #{$device-s} {
        margin-right: 0;
      }

      @media #{$device-l} {
      }

      >* {

        &:first-child {
          margin-top: 0 !important;
        }
      }

      p {
        margin-top: 0;
        line-height: 0;
        // transition-property:
        //   margin-top,
        //   line-height;
        transition-duration: inherit;
      }

      ::v-deep {
        img {
          display: block;
          width: 0;
          max-width: 100%;
          margin: 0 auto;
          transform: scale(0);
          transform-origin: center top;
          transition-duration: inherit;

          @media #{$device-l} {
            float: left;
            margin-right: 20px;
            margin-left: 0;
            transform-origin: left top;

            &+p {

              @media #{$device-l} {
                margin-left: 270px;
              }

              &::after {
                content: "";
                display: block;
                clear: both;
              }
            }

            &:first-child {

              &+p {
                margin-top: 0;
              }
            }
          }

          &:last-child {
            margin-bottom: 0;

            @media #{$device-l} {
              float: none;
              width: auto;
              max-width: 100%;
              margin-right: auto;
              margin-left: auto;
            }
          }
        }
      }
    }
  }
</style>
