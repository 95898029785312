<template>
  <dl
    class="news-list-category"
    :class="[setActiveClass]"
  >
    <dt>カテゴリ別に読む</dt>
    <dd class="tb-pc">
      <router-link
        :to="{name: 'NewsList'}"
      >
        <span>All</span>
      </router-link>
    </dd>
    <dd
      v-for="(category, index) in categories"
      :key="index"
    >
      <NewsCategory
        :category-id="category.id"
        isLink
      />
    </dd>
  </dl>
</template>

<script>
  import Categories from '@/jsons/newsCategories.json'
  import NewsCategory from '@/components/common/NewsCategory.vue'

  export default {
    name: 'NewsListCategories',
    components: {
      NewsCategory,
    },
    props: {
      activeCategory: [String, Boolean],
    },
    data () {
      return {
        categories: Categories.contents,
      }
    },
    computed: {
      setActiveClass () {
        if(this.activeCategory) {
          return ['is-' + this.activeCategory]
        } else {
          return []
        }
      },
    },
    watch: {
    },
    methods: {
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .news-list-category {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    @media #{$device-s} {
      justify-content: flex-start;
    }

    dt {
      justify-self: flex-start;
      color: #707070;
      margin-right: 30px;
      font-size: 1.2rem;

      @media #{$device-s} {
        flex: 0 0 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }

      @media #{$device-dark} {
        color: inherit;
      }

      &+dd {
        margin-left: 0;
      }

      &+[class*="tb-pc"] {
        &+dd {
          @media #{$device-s} {
            margin-left: 0;
          }
        }
      }
    }

    dd {
      justify-self: flex-end;
      margin-left: 30px;

      @media #{$device-s} {
        margin-left: 14px;
      }

      ::v-deep {
        a {
          display: block;
          overflow: hidden;
          color: inherit;
          text-decoration: none;

          @media #{$device-l} {
            transition-duration: $duration;

            &:hover {
              text-decoration: none;
              opacity: $alpha;
            }
          }

          >span {
            padding: 0 10px;
          }
        }
      }
    }

    a > span,
    .news-category {
      display: flex;
      align-items: center;
      height: 22px;
      font-size: 1.2rem;

      @media #{$device-s} {
        box-sizing: border-box;
        height: 30px;
        min-width: 64px;
        padding: 0 10px;
        border: 1px solid;
        border-radius: 15px;
      }
    }

    @each $category, $color in $news-categories {
      &.is-#{$category} {
        a > span,
        .news-category:not(.is-#{$category}) {
          opacity: 0.25;
        }
      }
    }
  }
</style>
