<template>
  <div class="wrap">
    <Table />
  </div>
</template>

<script>
import Table from '@/components/common/commonProfileTable.vue'

export default {
  name: 'CompanyTable',
  components: {
    Table,
  },
  data() {
    return {
    }
  },
  created() {
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/functions.scss";

.wrap {
  @media #{$device-s} {
    padding: 0 10px;
  }
}

.profile-table /deep/ {
  $border-style: 1px solid #53bcc0;

  border: $border-style;
  border-right: none;
  border-left: none;
  margin: 50px 0;

  @media #{$device-l} {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
  }

  >dt,
  >dd {
    border-top: $border-style;
    padding: 0 30px;

    @media #{$device-l} {
      box-sizing: border-box;
      padding: 15px 30px;
      line-height: 2.0;
    }
  }

  >dt {
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;

    @media #{$device-l} {
      box-sizing: border-box;
      width: 250 / (250+640) * 100%;
      padding: 15px 30px;
      text-align: right;
    }

    &:first-child {
      border-top: none;

      &+dd {
        @media #{$device-l} {
          border-top: none;
        }
      }
    }
  }

  >dd {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 2.0;

    @media #{$device-l} {
      width: 640 / (250+640) * 100%;
      padding-left: 0;
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @media #{$device-l} {}

    dt,
    dd {
      margin-top: 5px;

      @media #{$device-l} {}
    }

    dt {
      flex: 0 0 auto;
      width: 6.5em;
      text-align: right;
      white-space: nowrap;

      @media #{$device-l} {}

      &::after {
        content: "：";
      }

      &:first-child {

        &,
        &+dd {
          margin-top: 0;
        }
      }
    }

    dd {
      box-sizing: border-box;
      width: calc(100% - 105px);
      padding-left: 5px;

      @media #{$device-l} {}
    }
  }

  ul {

    @media #{$device-l} {}

    li {
      margin-top: 5px;

      @media #{$device-l} {}

      &:first-child {
        margin-top: 0;
      }
    }
  }

  a {
    color: map-get($color-brand, "accent");
    text-decoration: none;

    @media #{$device-l} {
      &:hover {
        text-decoration: underline;
        opacity: $alpha;
      }
    }
  }
}
</style>
