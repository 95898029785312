<template>
  <div class="news-pickup-wrapper">
    <div class="news-pickup">
      <h3 class="news-pickup-heading">Pick up</h3>
      <div class="news-pickup-information">
        <NewsDate :newsDate="post.dateTime" />
        <NewsCategory :category-id="post.category.id" />
      </div>
      <router-link
        class="news-pickup-post"
        :to= "{
          name: 'NewsPost',
          params: {
            categoryId: post.category.id,
            postId: post.id,
          },
        }"
      >
        <h4 class="news-pickup-title" v-html="$sanitize(post.title)"></h4>
        <p class="news-pickup-text" v-html="$sanitize(post.body)"></p>
      </router-link>
      <router-link
        class="news-pickup-image"
        :to= "{
          name: 'NewsPost',
          params: {
            categoryId: post.category.id,
            postId: post.id,
          },
        }"
      >
        <img v-if="post.thumbnail" :src="post.thumbnail.url" alt="" />
        <template v-else>
          <img class="is-light" :src="require('@/assets/images/news/thum_light.jpg')" alt="" />
          <img class="is-dark" :src="require('@/assets/images/news/thum_dark.jpg')" alt="" />
        </template>
      </router-link>
    </div>
  </div>
</template>

<script>
  import NewsCategory from '@/components/common/NewsCategory.vue'
  import NewsDate from '@/components/common/NewsDate.vue'

  export default {
    name: 'NewsPickUp',
    components: {
      NewsCategory,
      NewsDate,
    },
    props: {
      post: Object,
    },
    data () {
      return {
      }
    },
    computed: {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style lang="scss">
  .heading-wrapper {
    display: block;
    text-align: center;
  }
</style>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .news-pickup {
    &-wrapper {
      position: relative;
      width: calc(100% - #{30px * 2});
      max-width: 1100px;
      margin: 0 auto 30px;

      @media #{$device-s} {
        width: 100%;
      }

      &::before {
        content: "";
        display: block;
        padding-top: 249px / 708px * 100%;

        @media #{$device-s} {
          content: none;
        }

        @media #{$device-l} {
          padding-top: 400px / 1100px * 100%;
        }
      }
    }

    $max: 65px + 25px + 10px * 2;

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: grid;
    grid-template:
      "image heading    " 65px-20px
      "image information" 25px
      "image post       " calc(100% - #{$max - 20px}) /
      calc(50% - 15px) calc(50% - 15px);
    column-gap: 30px;
    overflow: hidden;
    width: 100%;
    margin: auto;

    @media #{$device-s} {
      box-sizing: border-box;
      position: static;
      display: flex;
      flex-direction: column;
      padding: 0 25px;
      margin-bottom: 30px;
    }

    @media #{$device-l} {
      grid-template:
        "image heading    " 65px
        "image information" 25px
        "image post       " calc(100% - #{$max}) /
        57.27% calc(42.73% - 40px);
      column-gap: 40px;
      row-gap: 10px;
      padding: 0;
    }

    &-heading {
      grid-area: heading;
      align-self: flex-end;
      margin-bottom: 10px;
      font-size: 2.4rem;
      letter-spacing: 0.025em;

      @media #{$device-s} {
        align-self: flex-start;
        margin-top: 30px;
      }

      @media #{$device-l} {
        margin-bottom: 5px;
        font-size: 4.2rem;
      }
    }

    &-information {
      grid-area: information;
      display: flex;
      align-items: center;

      @media #{$device-l} {
      }

      &::before {
        content: "";
        display: block;
        width: 1px;
        height: 12px;
        margin: 0 10px;
        background-color: map-get($color-text, "base");
        transform-origin: center center;
        transform: skew(-25deg);

        @media #{$device-dark} {
          background-color: #fff;
        }
      }
    }

    .news {
      &-date,
      &-category {
        font-size: 1.6rem;
      }

      &-date {
        order: -1;
      }
    }

    &-post {
      grid-area: post;
      position: relative;
      display: block;
      overflow: hidden;
      margin-bottom: 15px;
      color: map-get($color-text, "link");
      text-decoration: none;

      @media #{$device-s} {
        margin-bottom: 0;
      }

      @media #{$device-l} {
        transition-duration: $duration;

        &:hover {
          opacity: 0.7;
        }
      }

      @media #{$device-dark} {
        color: inherit;
      }

      &::after {
        flex: 0 0 auto;
        content: "";
        position: absolute;
        z-index: 10;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        height: 50px;
        margin: 0 auto;
        @include gradientBottom;

        @media #{$device-dark} {
          @include gradientBottom(map-get($color-dark, "main"));
        }
      }
    }

    &-title {
      position: relative;
      z-index: 20;
      overflow: hidden;
      margin-bottom: 10px;
      line-height: 1.5;
      font-size: 2.0rem;
      font-weight: bold;
      letter-spacing: 0.025em;

      @media #{$device-s} {
        height: auto;
        margin-top: 5px;
        line-height: 1.8;
        font-size: 1.8rem;
      }

      @media #{$device-l} {
        height: 3em * 1.5;
        font-size: 2.4rem;
      }
    }

    &-text {
      overflow: hidden;
      height: 5em * 1.7;
      line-height: 1.7;

      @media #{$device-s} {
        height: 6em * 1.8;
        line-height: 1.8;
        font-size: 1.2rem;
      }
    }

    &-image {
      grid-area: image;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      max-width: 630px;
      max-height: 400px;
      overflow: hidden;
      margin: 0;

      @media #{$device-s} {
        display: block;
        order: -1;
        margin: 0 -25px;
      }

      @media #{$device-l} {

        &:hover {
          img {
            transform: scale(1.1);
          }
        }
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media #{$device-l} {
          transform-origin: center center;
          transition-duration: $duration;
        }
      }
    }
  }
</style>
