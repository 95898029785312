<template>
  <div class="office">
    <div class="wrap">
      <section>
        <h3 class="heading">Our Office</h3>
        <ul class="office-access">
          <li
            v-for="(access, name) in officeDatas"
            :key="name"
          >
            <section>
              <h4 class="office-access-name">{{ name }}</h4>
              <p v-html="access"></p>
            </section>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
  import Datas from '@/assets/json/siteinfo.json'

  export default {
    name: 'CompanyOffice',
    components: {
    },
    data () {
      return {
        officeDatas: Datas.office,
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .wrap {
    @media #{$device-s} {
      padding: 0 10px;
    }
  }

  .heading {
    margin-bottom: 30px;
    font-size: 2.0rem;
    font-weight: bold;

    @media #{$device-l} {
    }
  }

  .office {
    color: #626262;

    @media #{$device-l} {
      grid-template-rows: 50% 50%;
    }

    @media #{$device-dark} {
      color: map-get($color-dark, "text");
    }

    &-access {
      display: flex;
      flex-wrap: wrap;

      >li {
        width: calc(50% - 10px);
        margin-top: 30px;
        margin-right: 20px;

        &:nth-child(-n+2) {
          margin-top: 0;
        }

        &:nth-child(2n+2) {
          margin-right: 0;
        }
      }

      &-name {
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 1.6rem;
        margin-bottom: 5px;

        @media #{$device-l} {
        }
      }

      p {
        line-height: 1.4;
        font-size: 1.4rem;

        @media #{$device-l} {
        }
      }
    }
  }
</style>
