<template>
  <p class="news-date">
    <time :datetime="newsDate">{{ getNewsDate( newsDate ) }}</time>
  </p>
</template>

<script>
  export default {
    name: 'NewsDate',
    props: {
      newsDate: String,
    },
    components: {
    },
    data () {
      return {
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
      getNewsDate(date) {
        return String(String(date).split('T')[0]).split('-').join('.')
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .news-date {
    line-height: 1.0;
    font-size: 1.4rem;
    color: inherit;
    letter-spacing: 0.05em;

    time {
      display: block;
    }
  }
</style>
