<template>
  <figure class="member-profile clearfix">
    <div class="member-image">
      <img :src="require('@/assets/images/about/about_member_'+member.id+'.png')" alt="">
    </div>
    <figcaption>
      <section>
        <h4 class="member-name">{{member.name}}</h4>
        <p class="member-position">{{member.position}}</p>
        <ul
          class="member-career"
        >
          <li
            v-for="(career, j) in member.career"
            :key="j"
            v-html="career"
          ></li>
        </ul>
        <p
          v-if="member.officer"
          class="member-button"
        >
          <Button
            :path="{name: 'Founder', hash: '#' + member.id}"
            :text="member.officer+'のメッセージを読む'"
          />
        </p>
      </section>
    </figcaption>
  </figure>
</template>

<script>
  import Button from '@/components/common/LinkButton.vue'

  export default {
    name: 'AboutMemberProfile',
    props: {
      member: Object,
    },
    components: {
      Button,
    },
    data () {
      return {
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .member {
    &-profile {

      @media #{$device-l} {
      }

      figcaption {
        box-sizing: border-box;
        width: calc(100% - 35vw);
        max-width: 650px;
        padding-left: 30px;
        margin-left: auto;

        @media #{$device-s} {
          width: auto;
          padding: 0;
          margin-right: auto;
        }

        @media #{$device-l} {
          width: calc(100% - 300px);
          max-width: unset;
          padding-left: 0;
          margin-left: 300px;
        }

        >section {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows   : repeat(3, auto);
          grid-template-areas:
            "name        "
            "position    "
            "introduction"
            "career      "
            "button      ";
          padding-top: 10px;

          @media #{$device-s} {
            display: flex;
            flex-direction: column;
            margin: 0;
          }

          @media #{$device-l} {
            grid-template-columns: 1fr 200px;
            grid-template-areas:
              "name         button      "
              "position     position    "
              "introduction introduction"
              "career       career      ";
            padding-top: 30px;
          }
        }
      }
    }

    &-image {
      float: left;
      display: block;
      width: 35vw;
      margin-bottom: 20px;

      @media #{$device-s} {
        float: none;
        width: 50vw;
        min-width: 200px;
        max-width: inherit;
        margin: 0 auto;
      }

      @media #{$device-l} {
        max-width: 250px;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    &-name {
      grid-area: name;
      margin: 7px 0 15px;
      font-size: 2.4rem;
      letter-spacing: 0.05em;

      @media #{$device-s} {
        margin: 20px 0 15px;
        text-align: center;
      }
    }

    &-position {
      grid-area: position;
      color: map-get($color-brand, "accent");
      font-weight: bold;

      @media #{$device-s} {
        text-align: center;
        font-size: 1.4rem;
      }

      @media #{$device-l} {
      }
    }

    &-introduction {
      grid-area: introduction;
      margin-top: 10px;
      line-height: 2.0;

      ::v-deep a {
        color: map-get($color-brand, "accent");

        @media #{$device-l} {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    &-career {
      grid-area: career;
      margin-top: 15px;

      @media #{$device-s} {
        margin-top: 25px;
      }

      >li, dd {
        margin-top: 10px;
        line-height: 1.8;

        @media #{$device-s} {
          margin-top: 15px;
          line-height: 2.0;
        }

        @media #{$device-l} {
        }

        &:first-child {
          margin-top: 0;
        }
      }

      dd {
        position: relative;
        padding-left: 20px;

        &::before {
          $size: 6px;

          content: "";
          position: absolute;
          top: calc(#{1.8em / 2} - #{$size / 2});
          left: 0;
          display: block;
          width: $size;
          height: $size;
          border-radius: 50%;
          background-color: map-get($color-text, "base");

          @media #{$device-s} {
            top: calc(#{2.0em / 2} - #{$size / 2});
          }

          @media #{$device-dark} {
            background-color: #fff;
          }
        }
      }
    }

    &-button {
      grid-area: button;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 20px;

      @media #{$device-s} {
        justify-content: center;
        margin-top: 20px;
        width: auto;
      }

      @media #{$device-l} {
        margin-top: 0;
      }
    }
  }
</style>
