<template>
  <div class="product">
    <div class="wrap">
      <section>
        <h3 class="product-heading">
          <Heading
            section-class="content"
            jp-text = "プロダクト紹介"
            en-text = "Product"
          />
        </h3>
        <div class="product-list">
          <div
            class="product-item"
            v-for="(product, index) in productDatas"
            :key="index"
          >
            <section>
              <h4
                class="product-name"
                v-html="product.name"
              ></h4>
              <div class="product-text">
                <p
                  v-for="(text, j) in product.text"
                  :key="j"
                  v-html="text"
                ></p>
              </div>
              <div class="product-image">
                <img :src="require('@/assets/images/home/'+product.image)" alt="">
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import Heading from '@/components/common/PageHeading.vue'

  export default {
    name: 'HomeProduct',
    components: {
      Heading,
    },
    data () {
      return {
        productDatas: [
          {
            'name': 'Metamo Sphere',
            'text': [
              '世の中の全てにタグ（Tag）要素を付けて管理する新型グラフデータベース。Deep Learning（深層学習）をはじめとしたA.I.関連の技術も駆使して、あらゆるモノ・コト・情報・地理・事象・行動・動きをベクトル空間で表現します。',
              '非連続性なあらゆるデータの関連度を算出しやすくするだけでなく、最適なデータを適切なタイミングとシーンで記録・読込することができる画期的なデータベースです。',
              'メタモが提供するサービスの多くは Metamo Sphere を利用しています。',
            ],
            'image': 'home_product_msp.jpg'
          },
          {
            'name': 'Metamo Card',
            'text': [
              '物理カード（Physical Card）とバーチャルカード（Virtual Card）を使って、メタモが提供する様々なサービスに安全にアクセスする手段です。',
              '個人情報はカード内に記録されていないので紛失時も安心・安全。QRコードとICチップはセキュア認証に利用されます。',
              '対応サービスは一定のセキュリティ及びプライバシー基準を設けており、それに準拠したサービスのみが利用可能であることも特徴の一つです。',
            ],
            'image': 'home_product_card.jpg'
          },
          {
            'name': 'fooTPath',
            'text': [
              '自分自身を最大限にアピールできるキャリアの棚卸しツール。フリーランス・正社員などの雇用形態に限らず全ての人々に活用して欲しいと願う「人間を自由にするツール」です。',
              '就労の記録を自分の手元で管理しない時代はリスクになりました。それは日本だけでなく世界各国同じことです。',
              'アピールする際に表示するデータは、あなたが自由に範囲や内容を決めることができます。監視社会のような息苦しさを感じさせないサービスにすることを心がけました。',
            ],
            'image': 'home_product_footpath.jpg'
          },
          {
            'name': 'Metamo Ambient Safety',
            'text': [
              '手軽に設置できるAI時代の新型ホームセキュリティ。アポ電強盗を撃退した実績のある次世代セキュリティ・ソリューションです。',
              '従来の「待ちの守り」から「攻める守り（Active Defence）」に進化させ、犯人が避けたくなる家に変化します。',
              '高精度な顔認識、複数のカメラ連携による追従、不自然なうろつき検出、大音量アラーム、非常通報など。多発するリレー方式などによる自動車盗難への対応も可能です。',
            ],
            'image': 'home_product_ambient.jpg'
          },
          {
            'name': 'かりるパートナー',
            'text': [
              '歳を重ねても賃貸物件を自由に選べる社会を目指して、入居に関わる不安を最新テクノロジーであんしんサポートします。',
              '60歳以上の半分以上の方が賃貸の入居審査断れる現状をテクノロジーで解決すべく、始まったプロジェクト。',
              '物件のオーナーにとってもメリットがあり、入居可能な年齢層を高めることで物件の流動性を高めて空室率を低下させる一方で、様々なリスクを軽減できるメリットがあります。',
            ],
            'image': 'home_product_karipa.jpg'
          },
          {
            'name': 'Development Now...',
            'text': [
              'コトとデータを結びつけることがこれからのトレンドです。',
              '人間を自由にするデータテクノロジーカンパニーとして、メタモは次の解決すべき課題に取り組んでいます。',
            ],
            'image': 'home_product_develop.jpg'
          },
        ]
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .product {
    position: relative;
    padding: 40px 0 250px;
    margin-top: 75px;

    @media #{$device-l} {
      padding: 60px 0 580px;
      margin-top: 250px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      background-color: map-get($color-brand, "base");

      @media #{$device-dark} {
        background-color: rgba(#fff, 0.1);
      }
    }

    &::before {
      top: 0;
      left: 0;
      width: 120px;
      height: 60%;

      @media #{$device-l} {
        width: 622px / 1500px * 100%;
        height: 80%;
      }
    }

    &::after {
      right: 0;
      bottom: 0;
      width: 150px;
      height: 30%;

      @media #{$device-l} {
        width: 267px / 1500px * 100%;
      }
    }

    .wrap {
      @media #{$device-l} {
        max-width: unset;
      }
    }

    &-heading {
      margin-bottom: 35px;

      @media #{$device-l} {
      }

      /deep/ .heading {
        display: block;
        margin: 0 auto;
      }
    }

    &-list {
      position: relative;
      right: -#{$padding / 2};
      left: -#{$padding / 2};
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      width: calc(100% + #{$padding});
      max-width: 450px * 3;
      margin: -60px auto 0;

      @media #{$device-s} {
        position: static;
        display: block;
        width: 100%;
        margin-top: -100px + 35px;
      }
    }

    &-item {
      box-sizing: border-box;
      display: flex;
      width: (100% / 2);
      margin-top: 60px;
      position: relative;

      @media #{$device-s} {
        display: block;
        width: 100%;
        margin-top: 100px;
      }

      @media #{$device-l} {
        width: (100% / 3);
      }

      &::before,
      &::after {
        flex: 0 0 auto;
        content: "";
        display: block;
        width: 1.65vw;
        min-width: $padding / 2;
        max-width: 25px;
      }

      >section {
        z-index: 1;
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }

    &-name {
      position: relative;
      margin: 20px 0 15px;
      line-height: 1.5;
      font-size: 2.8rem;

      @media #{$device-s} {
        margin: 10px 0 15px;
        text-shadow: 1px 1px 12px #fff;
      }

      @media #{$device-dark} {
        text-shadow: 1px 1px 12px map-get($color-dark, "main");
      }
    }

    &-text {
      line-height: 2.0;
      font-size: 1.4rem;

      @media #{$device-s} {
        line-height: 2.5;
        font-size: 1.0rem;
      }

      >p {
        margin-top: 25px;

        @media #{$device-s} {
          margin-top: 15px;
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &-image {
      flex: 0 0 auto;
      order: -1;
      width: 100%;

      @media #{$device-l} {
        margin: 0;
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }
</style>
