var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news-post"},[_c('article',[_c('h2',{staticClass:"news-post-title"},[_vm._v(" "+_vm._s(_vm.post.title)+" "),(_vm.post.subTitle)?_c('small',[_vm._v(_vm._s(_vm.post.subTitle))]):_vm._e()]),_c('div',{staticClass:"news-post-information"},[_c('NewsCategory',{attrs:{"category-id":_vm.post.category.id}}),_c('NewsDate',{attrs:{"newsDate":_vm.post.dateTime}})],1),(_vm.post.pdf)?_c('div',{staticClass:"news-post-pdf"},[_c('a',{attrs:{"href":_vm.post.pdf.url,"target":"_blank"}},[_vm._v(" PDFダウンロード "),_c('PDFIcon')],1)]):_vm._e(),_c('div',{staticClass:"news-post-body",attrs:{"id":"post-body"},domProps:{"innerHTML":_vm._s(_vm.post.body)}})]),_c('div',{staticClass:"news-other"},[_c('p',{staticClass:"news-other-button is-prev",class:{'is-disable': !_vm.aroundPost.prev}},[(_vm.aroundPost.prev)?_c('router-link',{staticClass:"news-other-button-inner",attrs:{"to":{
          name: 'NewsPost',
          params: {
            categoryId: _vm.aroundPost.prev.categoryId,
            postId: _vm.aroundPost.prev.id,
          },
        }}},[_vm._v(" 前のページへ ")]):_c('span',{staticClass:"news-other-button-inner"},[_vm._v("前のページへ")])],1),_c('p',{staticClass:"news-other-button"},[_c('router-link',{staticClass:"news-other-button-inner",attrs:{"to":{name: 'NewsList'}}},[_vm._v(" ニュース一覧 ")])],1),_c('p',{staticClass:"news-other-button is-next",class:{'is-disable': !_vm.aroundPost.next}},[(_vm.aroundPost.next)?_c('router-link',{staticClass:"news-other-button-inner",attrs:{"to":{
          name: 'NewsPost',
          params: {
            categoryId: _vm.aroundPost.next.categoryId,
            postId: _vm.aroundPost.next.id,
          },
        }}},[_vm._v(" 次のページへ ")]):_c('span',{staticClass:"news-other-button-inner"},[_vm._v("次のページへ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }