<template>
  <div class="l-container">
    <Header />
    <Main>
      <section>
        <Heading
          level = "2"
          :jp-text = "pageTitle"
          en-text = "Founder Message"
        />
        <div class="wrap">
          <LeadText>
            <p>
              パーソナルデータとA.I.は次のステージへ。<br>
              ヒト・モノ・コトとデータを安心・安全に繋ぐ<br>
              人間を自由にするデータテクノロジーカンパニー<br>
              「メタモ（Metamo）」の共同創設者３名をご紹介します。
            </p>
          </LeadText>
        </div>
        <SideNav
          :navigation = navigation
          :fixed-position-ajustment="185"
        />
        <Member />
      </section>
      <LinkList>
        <li>
          <LinkBox
            :path = "{name: 'Company'}"
          />
        </li>
        <li>
          <LinkBox
            :path = "{name: 'About'}"
          />
        </li>
      </LinkList>
    </Main>
    <Footer />
  </div>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'

  import Header from '@/components/essentials/Header.vue'
  import Main from '@/components/essentials/Main.vue'
  import Footer from '@/components/essentials/Footer.vue'

  import SideNav from '@/components/essentials/SideNavigation.vue'

  import Heading from '@/components/common/PageHeading.vue'
  import LeadText from '@/components/common/LeadText.vue'
  import LinkList from '@/components/common/LinkList.vue'
  import LinkBox from '@/components/common/LinkBox.vue'

  import Member from '@/components/founder/FounderMember.vue'

  export default {
    name: 'Founder',
    components: {
      Header,
      Main,
      Footer,
      SideNav,
      Heading,
      LeadText,
      LinkList,
      LinkBox,
      Member,
    },
    data () {
      return {
        SiteInfo: SiteInfo,
        pageTitle: '創業者メッセージ',
        description: 'メタモの創業者である３人の役員からのメッセージを紹介します。',
        navigation: [
          {
            "path": "yuta",
            "text": "CEO 佐藤 由太",
          },
          {
            "path": "maina",
            "text": "CDO 柴田 舞奈",
          },
          {
            "path": "hiroki",
            "text": "CSO 斎藤 洋希",
          },
        ],
      }
    },
    created () {
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    head: {
      title () {
        return {
          inner: this.pageTitle,
        }
      },
      meta () {
        return this.defaultHead('meta', [
          { name: 'description', content: this.description },
          { property: 'og:title', content: this.pageTitle + ' | ' + this.SiteInfo.titleShort },
          { property: 'og:description', content: this.description },
          { property: 'og:url', content: this.SiteInfo.baseURL + location.pathname },
        ])
      },
      link () {
        return this.defaultHead('link', [
          { rel: 'canonical', href: this.SiteInfo.baseURL + location.pathname },
        ])
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .side-navigation-wrapper {
    @media #{$device-l} {
      margin-bottom: 50px;
    }
  }
</style>
