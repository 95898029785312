<template>
  <div
    class="lead-text"
    :class="[setAlignClass]"
  >
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'LeadText',
    props: {
      alignClass: String,
    },
    data () {
      return {
      }
    },
    computed: {
      setAlignClass () {
        if(this.alignClass) {
          return ['is-' + this.alignClass]
        } else {
          return []
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .lead-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2.7;
    text-align: center;
    color: #626262;

    @media #{$device-dark} {
      color: map-get($color-dark, "text");
    }

    @media #{$device-l} {
      line-height: 2.0;
      font-size: 1.6rem;
    }

    &.is-left {
      flex: 0 0 auto;
      text-align: left;

      p {
        width: 80%;

        @media #{$device-s} {
          width: 100% !important;
        }

        &:only-child {
          width: auto;
        }
      }
    }

    p {
      margin-top: 20px;

      @media #{$device-l} {
        margin-top: 40px;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    img {
      display: block;
      width: auto;
      max-width: 100%;
      margin: 30px auto;

      @media #{$device-l} {
        width: 100%;
        max-width: 750px;
        margin: 55px auto;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    a {
      color: map-get($color-brand, "accent");

      @media #{$device-l} {

        &:hover {
          text-decoration: none;
          opacity: $alpha;
        }
      }
    }
  }
</style>
