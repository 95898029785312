<template>
  <router-link
    v-if="path && Object.keys(path).length"
    class="link-box"
    :class="[setStyleClass()]"
    :to="path"
  >
    <span>{{ setText() }}</span>
  </router-link>
  <a
    v-else
    class="link-box"
    :class="[setStyleClass()]"
    :href="path"
  >
    <span>{{ setText() }}</span>
  </a>
</template>

<script>
  export default {
    name: 'LinkBox',
    components: {
    },
    props: {
      path: {
        type: [String, Object],
        default: () => {}
      },
    },
    data () {
      return {
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
      setStyleClass () {
        if(this.path.name) {
          return ['is-' + (this.path.name).toLowerCase()]
        } else {
          return []
        }
      },
      setText () {
        switch(this.path.name) {
          case 'About':
            return 'メタモについて'
          case 'Company':
            return '会社概要'
          case 'Founder':
            return '創業者メッセージ'
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .link-box {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    line-height: 1.5;
    color: #fff;
    text-decoration: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    cursor: pointer;
    backface-visibility: hidden;

    @media #{$device-l} {
      width: 680px;
      font-size: 1.8rem;
      transition-duration: $duration / 2;

      &:hover {

        &::before {
          transform: scale(1.1);
        }

        &::after {
          opacity: 1;
        }
      }
    }

    &::before,
    &::after {
      @media #{$device-l} {
        transition-duration: $duration / 2;
      }
    }

    &::before {
      content: "";
      width: 100%;
      padding-top: 158px / 450px * 100%;
      user-select: none;
      background: inherit;

      @media #{$device-l} {
        padding-top: 240px;
        transform: center center;
      }
    }

    &::after {
      @media #{$device-l} {
        content: "";
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 3px solid map-get($color-brand, "accent");
        margin: auto;
        pointer-events: none;
        opacity: 0;
      }
    }

    >span {
      position: absolute;
      z-index: 1;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      min-width: 100px;
      max-width: calc(100% - 20px);
      height: 50px;
      padding: 0 20px + 20px;
      padding-left: 20px + 45px;
      text-align: center;

      @media #{$device-l} {
        min-width: 170px;
        height: 70px;
        padding: 0 30px + 35px;
        padding-left: 30px + 70px;
      }

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        right: 0;
        bottom: 0;
        width: 100%;
        height: inherit;
        background-color: map-get($color-brand, "accent");
        user-select: none;
        transform: skew(-45deg);
        transform-origin: left bottom;

        @media #{$device-l} {
        }
      }
    }

    $pages: (
      "about",
      "company",
      "founder",
    );

    @each $page in $pages {
      &.is-#{$page} {
        background-image: url('~@/assets/images/#{$page}/#{$page}_link_box.jpg');

        @media #{$device-dark} {
          background-image: url('~@/assets/images/#{$page}/#{$page}_link_box_dark.jpg');
        }
      }
    }
  }
</style>
