<template>
  <div class="member">
    <section>
      <h3 class="heading-wrapper">
        <Heading jp-text="役員紹介" en-text="Member of Boards" />
      </h3>
      <div class="wrap">
        <ul class="member-list">
          <li v-for="(member, index) in memberDatas.member" :key="index">
            <Profile :member="member" />
          </li>
        </ul>
      </div>
    </section>
    <section v-if="memberDatas.vice">
      <h3 class="heading-wrapper">
        <Heading jp-text="執行役員" en-text="Vice President" />
      </h3>
      <div class="wrap">
        <ul class="member-list">
          <li v-for="(member, index) in memberDatas.vice" :key="index">
            <Profile :member="member" />
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import Datas from '@/assets/json/aboutMember.json'
import Heading from '@/components/common/PageHeading.vue'
import Profile from '@/components/about/AboutMemberProfile.vue'

export default {
  name: 'AboutMember',
  components: {
    Heading,
    Profile,
  },
  data() {
    return {
      memberDatas: Datas,
    }
  },
  created() {
  },
  watch: {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/functions.scss";

.member {
  display: block;
  overflow: hidden;
  padding: 50px 0 0;

  @media #{$device-l} {
    padding-top: 100px;
  }

  .heading {
    margin-top: 0;
    margin-bottom: 0;

    @media #{$device-s} {}
  }

  &-list {
    padding: 0;
    margin: 50px auto;

    @media #{$device-l} {
      width: 70vw;
      min-width: 750px;
      max-width: 950px;
      margin-top: 75px;
      margin-bottom: 100px;
    }

    >li {
      position: relative;
      margin-top: 60px;

      @media #{$device-l} {
        margin-top: 110px;
      }

      &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: (35vw / 2);
        left: (35vw / 2);
        width: 1px;
        height: 100%;
        background-color: #707070;

        @media #{$device-s} {
          content: none;
        }

        @media #{$device-l} {
          top: (250px / 2);
          left: (250px / 2);
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        &::before {
          content: none;
        }
      }
    }
  }

  &-profile {

    @media #{$device-l} {}

    figcaption {
      box-sizing: border-box;
      width: calc(100% - 35vw);
      max-width: 650px;
      padding-left: 30px;
      margin-left: auto;

      @media #{$device-s} {
        width: auto;
        padding: 0;
        margin-right: auto;
      }

      @media #{$device-l} {
        width: calc(100% - 300px);
        max-width: unset;
        padding-left: 0;
        margin-left: 300px;
      }

      >section {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
          "name        "
          "position    "
          "introduction"
          "career      "
          "button      ";
        padding-top: 10px;

        @media #{$device-s} {
          display: flex;
          flex-direction: column;
          margin: 0;
        }

        @media #{$device-l} {
          grid-template-columns: 1fr 200px;
          grid-template-areas:
            "name         button      "
            "position     position    "
            "introduction introduction"
            "career       career      ";
          padding-top: 30px;
        }
      }
    }
  }

  &-image {
    float: left;
    display: block;
    width: 35vw;
    margin-bottom: 20px;

    @media #{$device-s} {
      float: none;
      width: 50vw;
      min-width: 200px;
      max-width: inherit;
      margin: 0 auto;
    }

    @media #{$device-l} {
      max-width: 250px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &-name {
    grid-area: name;
    margin: 7px 0 15px;
    font-size: 2.4rem;
    letter-spacing: 0.05em;

    @media #{$device-s} {
      margin: 20px 0 15px;
      text-align: center;
    }
  }

  &-position {
    grid-area: position;
    color: map-get($color-brand, "accent");
    font-weight: bold;

    @media #{$device-s} {
      text-align: center;
      font-size: 1.4rem;
    }

    @media #{$device-l} {}
  }

  &-introduction {
    grid-area: introduction;
    margin-top: 10px;
    line-height: 2.0;

    ::v-deep a {
      color: map-get($color-brand, "accent");

      @media #{$device-l} {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &-career {
    grid-area: career;
    margin-top: 15px;

    @media #{$device-s} {
      margin-top: 25px;
    }

    >li,
    dd {
      margin-top: 10px;
      line-height: 1.8;

      @media #{$device-s} {
        margin-top: 15px;
        line-height: 2.0;
      }

      @media #{$device-l} {}

      &:first-child {
        margin-top: 0;
      }
    }

    dd {
      position: relative;
      padding-left: 20px;

      &::before {
        $size: 6px;

        content: "";
        position: absolute;
        top: calc(#{1.8em / 2} - #{$size / 2});
        left: 0;
        display: block;
        width: $size;
        height: $size;
        border-radius: 50%;
        background-color: map-get($color-text, "base");

        @media #{$device-s} {
          top: calc(#{2.0em / 2} - #{$size / 2});
        }

        @media #{$device-dark} {
          background-color: #fff;
        }
      }
    }
  }

  &-button {
    grid-area: button;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;

    @media #{$device-s} {
      justify-content: center;
      margin-top: 20px;
      width: auto;
    }

    @media #{$device-l} {
      margin-top: 0;
    }
  }
}
</style>
