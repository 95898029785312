<template>
  <p
    v-if="!isLink"
    class="news-category"
    :class="'is-'+categoryId"
  >{{ categories[categoryId] }}</p>

  <p
    v-else
    class="news-category"
    :class="'is-'+categoryId"
  >
    <router-link
      :to="{
        name: 'NewsCategoryList',
        params: {
          category: categoryId,
        },
      }"
    >{{ categories[categoryId] }}</router-link>
  </p>
</template>

<script>
  export default {
    name: 'NewsCategory',
    props: {
      isLink: {
        type: Boolean,
        default: false,
      },
      categoryId: String,
    },
    components: {
    },
    data () {
      return {
        categories: {
          "press": "Press Release",
           "news": "News",
          "media": "Media",
        },
      }
    },
    created () {
    },
    watch: {
    },
    methods: {
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .news-category {
    flex: 0 0 auto;
    display: flex;
    width: auto;
    height: 22px;
    justify-content: center;
    align-items: center;
    line-height: 1.0;
    font-size: 1.2rem;
    color: inherit;
    white-space: nowrap;
    letter-spacing: 0.025em;

    @media #{$device-l} {
      height: 25px;
      font-size: 1.4rem;
    }

    @each $key, $color in $news-categories {
      &.is-#{$key} {
        border-color: $color;
        color: $color;
      }
    }

    a {
      color: inherit;
      text-decoration: none;

      @media #{$device-l} {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
</style>
