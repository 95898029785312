<template>
  <header>
    <div class="l-header">
      <div class="wrap">
        <h1 class="site-logo">
          <router-link
            :to="{name: 'Metamo'}"
          >
            <Logo />
            <span class="site-logo-text">{{this.SiteInfo.title}}</span>
          </router-link>
        </h1>
        <div
          class="global-navigation"
          :class="{'is-open': navigationFlag}"
        >
          <p
            class="global-navigation-button sp-tb"
            v-on:click="[navigationFlag = !navigationFlag, setBodyScroll(navigationFlag)]"
          >
            <span class="global-navigation-button-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
            <span class="global-navigation-button-text">
              <span class="global-navigation-button-text-open">MENU</span>
              <span class="global-navigation-button-text-close">CLOSE</span>
            </span>
          </p>
          <p class="site-logo global-navigation-logo sp">
            <router-link
              :to="{name: 'Metamo'}"
            >
              <Logo />
            </router-link>
          </p>
          <nav>
            <ul class="global-navigation-list">
              <li>
                <router-link
                  @click.native="PageScrollReset()"
                  :to="{name: 'Metamo'}"
                  :class="{'is-active': $route.path === '/'}"
                >ホーム</router-link>
              </li>
              <li>
                <router-link
                  @click.native="PageScrollReset()"
                  :to="{name: 'About'}"
                  :class="{'is-active': $route.path === '/about'}"
                >メタモについて</router-link>
              </li>
              <li>
                <router-link
                  @click.native="PageScrollReset()"
                  :to="{name: 'Company'}"
                  :class="{'is-active': $route.path === '/company'}"
                >会社概要</router-link>
              </li>
              <li>
                <router-link
                  @click.native="PageScrollReset()"
                  :to="{name: 'Founder'}"
                  :class="{'is-active': $route.path === '/founder'}"
                >創業者メッセージ</router-link>
              </li>
              <li class="is-line">
                <router-link
                  @click.native="PageScrollReset()"
                  :to="{name: 'NewsList'}"
                  :class="{'is-active': ($route.path).match(/news/)}"
                >ニュースルーム</router-link>
              </li>
              <li>
                <router-link
                  @click.native="PageScrollReset()"
                  :to="{name: 'Contact'}"
                  :class="{'is-active': $route.path === '/contact'}"
                >お問合わせ</router-link>
              </li>
              <li>
                <Button
                  :path="SiteInfo.careerURL"
                  color-class="accent"
                  text="採用サイト"
                />
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'

  import Logo from '@/components/common/BrandLogo.vue'
  import Button from '@/components/common/LinkButton.vue'

  export default {
    name: 'Header',
    components: {
      Logo,
      Button,
    },
    props: {
      isSimple: {
        type: Boolean,
        default: false,
      },
      notScroll: Boolean,
    },
    data () {
      return {
        SiteInfo: SiteInfo,
        navigationFlag: false,
      }
    },
    beforeDestroy() {
      this.setBodyScroll(false)
    },
    computed: {
      setFixedClass () {
        if(!this.notScroll) {
          return ['is-night']
        } else {
          return []
        }
      },
    },
    created () {
    },
    methods: {
      PageScrollReset () {
        this.setBodyScroll(false)
        window.scrollTo(0, 0)
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  $sp-button: 60px;
  $pc-logo: 138px;

  .l-header {
    box-sizing: border-box;
    position: absolute;
    z-index: 80;
    top: 0;
    right: 0;
    left: 0;
    height: $sp-button;
    padding: 15px 0 10px;
    background-color: #fff;
    transition-duration: $duration;

    @media #{$device-l} {
      position: fixed;
      height: auto;
      padding: 16px 0;
      color: #626262;
      box-shadow: 0 3px 6px rgba(#000, 0.25);
    }

    @media #{$device-dark} {
      background-color: map-get($color-dark, "main");
    }

    &.is-night {
      color: #fff;
      background-color: rgba(#14247b, 0.7);

      &::before {
        content: none;
      }

      .global-navigation {

        @media #{$device-l} {
          color: #fff;
        }

        &-child {
          >li {
            &::before {
              @media #{$device-l} {
                background-color: #fff;
              }
            }
          }
        }
      }
    }

    .wrap {
      padding: 0 15px;

      @media #{$device-l} {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px + 60px;
        padding: 0 $padding;
      }
    }
  }

  .site-logo {
    position: relative;
    width: 134.75px;

    @media #{$device-l} {
      width: $pc-logo;
    }

    &::before {
      content: "";
      display: block;
      padding-top: 120px / 462px * 100%;
    }

    >a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      overflow: hidden;
      margin: auto;
      text-indent: 100%;
      white-space: nowrap;
    }

    &-text {
      overflow: hidden;
      text-indent: 100%;
      color: transparent;
    }

    &.is-fixed {
      @media #{$device-m} {
        position: fixed;
        z-index: 100;
        top: 15px;
        left: 15px;
      }
    }
  }

  .global-navigation {
    box-sizing: border-box;
    position: fixed;
    z-index: 90;
    right: 0;
    top: 0;
    width: $sp-button;
    height: auto;
    max-height: $sp-button;
    color: map-get($color-text, "link");
    transition-duration: $duration;
    background-color: #fff;

    @media #{$device-l} {
      position: static;
      width: calc(100% - #{$pc-logo + 30px});
      height: auto;
      max-height: none;
      background-color: transparent;
      margin-left: 30px;
      transition-duration: 0s;
    }

    @media #{$device-dark} {
      color: map-get($color-dark, "text");
      background-color: map-get($color-dark, "main");
    }

    &-button {
      position: absolute;
      z-index: 40;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: $sp-button;
      height: $sp-button;
      cursor: pointer;
      background-color: #fff;

      @media #{$device-dark} {
        background-color: map-get($color-dark, "main");
      }

      &-icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 26px;
        height: 18px;

        >span {
          display: block;
          width: inherit;
          height: 2px;
          background-color: map-get($color-brand, "border");
          transition-duration: $duration;
          transform-origin: left center;
        }
      }

      &-text {
        position: relative;
        width: inherit;
        height: 1.0em;
        margin-top: 6px;
        text-align: center;
        font-size: 0.8rem;

        >span {
          position: absolute;
          right: 0;
          left: 0;
          margin: 0 auto;
          color: #4C4B60;
          transition-duration: $duration;

          @media #{$device-dark} {
            color: map-get($color-dark, "text");
          }
        }

        &-open {
          opacity: 1;
        }

        &-close {
          opacity: 0;
        }
      }
    }

    &-logo {
      position: fixed;
      z-index: 30;
      top: 15px;
      right: (134.75px + 15px) * (-1);
      transition-duration: $duration;
      opacity: 0;
    }

    &-list {
      box-sizing: border-box;
      position: fixed;
      z-index: 20;
      top: 0;
      right: -100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
      padding: 60px+30px 0 50px+30px;
      font-size: 0;
      background-color: #fff;
      opacity: 0;
      pointer-events: none;
      transition-duration: $duration;

      @media #{$device-l} {
        position: relative;
        right: 0;
        flex-direction: row;
        justify-content: flex-end;
        width: auto;
        height: auto;
        padding: 0;
        background-color: transparent;
        opacity: 1;
        pointer-events: auto;
        transition-duration: 0s;
      }

      @media #{$device-dark} {
        background-color: map-get($color-dark, "main");
      }

      >li {
        flex: 0 0 auto;
        box-sizing: border-box;
        display: flex;
        width: calc(100vw - 120px);
        min-width: 230px;
        max-width: 400px;
        padding: 0 25px;
        margin-top: 5px;

        @media #{$device-l} {
          width: auto;
          min-width: 0;
          max-width: inherit;
          padding: 0;
          margin-top: 0;
        }

        &::before {
          @media #{$device-l} {
            flex: 0 0 auto;
            content: "";
            display: block;
            width: 2.25vw;
            min-width: 20px;
            max-width: 40px;
            height: 1px;
          }
        }

        &:first-child {
          margin-top: 0;

          @media #{$device-l} {
          }

          &::before {
            @media #{$device-l} {
              content: none;
            }
          }
        }

        &.is-line {
          padding-top: 5px + 15px;
          border-top: 1px solid map-get($color-brand, "border");
          margin-top: 5px + 15px;

          @media #{$device-l} {
            padding-top: 0;
            border: none;
            margin-top: 0;
          }
        }
      }

      p,
      a {
        display: flex;
        align-items: flex-start;
        overflow: hidden;
        padding: 15px;
        color: inherit;
        font-size: 1.6rem;
        text-decoration: none;

        @media #{$device-l} {
          width: auto;
          padding: 5px;
          font-size: 1.4rem;
          transition-duration: $duration;

          &:hover {
            &:not(.is-active) {
              opacity: $alpha;

              svg {
                color: #000;
              }
            }
          }
        }

        svg {
          margin-left: 8px;

          @media #{$device-l} {
            margin-left: 6px;
          }

          path {

            @media #{$device-dark} {
              stroke: map-get($color-dark, "text");
            }
          }
        }

        &.is-active {
          color: map-get($color-brand, "accent");
        }
      }

      .button {
        width: 100%;
        align-items: center;
        padding: 0 30px;
        margin: 0 15px;

        @media #{$device-l} {
          padding-left: 20px;
          margin: 0;
          margin-left: -10px;
        }
      }
    }

    &.is-open {

      .global-navigation {

        &-button {

          &-icon {
            >span {

              &:nth-child(1) {
                transform: translate(4px, -1px) rotate(45deg);
              }

              &:nth-child(2) {
                opacity: 0;
                transform: translateX(50%);
              }

              &:nth-child(3) {
                transform: translate(4px, 1px) rotate(-45deg);
              }
            }
          }

          &-text {

            &-open {
              opacity: 0;
            }

            &-close {
              opacity: 1;
            }
          }
        }

        &-logo {
          right: calc(100% - #{15px + 134.75px});
          opacity: 1;
        }

        &-list {
          right: 0;
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
</style>
