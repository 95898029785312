import SiteInfo from '@/assets/json/siteinfo.json'

export default {
  meta () {
    return [
      { name: 'description', content: SiteInfo.description },
      { property: 'og:title', content: SiteInfo.titleShort },
      { property: 'og:description', content: SiteInfo.description },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: SiteInfo.baseURL },
      { property: 'og:image', content: SiteInfo.baseURL + SiteInfo.ogp.image },
      { name: 'twitter:card', content: 'summary' },
      { name: 'fb:app_id', content: '143982742855609' },
    ]
  },
  link () {
    return [
      { rel: 'canonical', href: SiteInfo.baseURL }
    ]
  },
}
