<template>
  <div class="reading-content">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'ReadingContent',
    data () {
      return {
      }
    },
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .reading-content {
    line-height: 1.8;
    font-size: 1.4rem;
    color: #626262;

    @media #{$device-dark} {
      color: map-get($color-dark, "text");
    }

    @media #{$device-l} {
    }

    h3, h4, h5 {
      margin: 40px 0 20px;
      font-weight: bold;

      @media #{$device-l} {
        margin-top: 30px;
      }
    }

    h3 {
      padding-bottom: 5px;
      border-bottom: 1px solid #ABC8C9;
      line-height: 1.7;
      font-size: 2.0rem;

      @media #{$device-l} {
        margin-top: 40px;
        font-size: 2.4rem;
      }

      &+section {
        >h4:first-child {
          margin-top: 20px;
        }
      }
    }

    h4 {
      font-size: 1.8rem;

      @media #{$device-l} {
        font-size: 2.0rem;
      }
    }

    h5 {
      margin: 20px 0 5px;

      @media #{$device-l} {
      }

      &+p {
        margin-top: 5px;
      }
    }

    p {
      margin: 15px 0;

      @media #{$device-l} {
      }
    }

    strong {
      font-weight: bold;
    }

    a {
      color: map-get($color-brand, "accent");

      @media #{$device-l} {

        &:hover {
          text-decoration: none;
          opacity: $alpha;
        }
      }

      svg {
        margin: 0 2px;

        &, path {
          stroke: map-get($color-brand, "accent");
        }
      }
    }

    ul {
      margin: 20px 0;

      li {
        position: relative;
        padding-left: 1.5em;
        margin-top: 15px;

        &::before {
          $size: 6px;

          content: "";
          position: absolute;
          top: 9px;
          left: 5px;
          width: $size;
          height: $size;
          border-radius: 50%;
          display: block;
          background-color: map-get($color-text, "base");

          @media #{$device-dark} {
            background-color: #fff;
          }

          @media #{$device-l} {
          }
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    dl {

      dt {
        font-weight: bold;
      }

      dd {
      }
    }
  }
</style>
