<template>
  <div class="l-container">
    <Header />
    <Main>
      <section>
        <Hero>
          <Heading
            :jp-text = pageTitle
            en-text = "Contact"
          />
        </Hero>
        <div class="wrap">
          <LeadText
            align-class="left"
          >
            <p>
              この度は弊社にご興味をお持ちくださり誠にありがとうございます。<br>
              メタモ株式会社や弊社サービスへの質問、各種ご依頼がございましたら、<br>
              以下のメールアドレス宛にお問い合わせください。
            </p>
          </LeadText>
        </div>
        <Mail />
      </section>
      <LinkList>
        <li>
          <LinkBox
            :path = "{name: 'Founder'}"
          />
        </li>
        <li>
          <LinkBox
            :path = "{name: 'Company'}"
          />
        </li>
      </LinkList>
    </Main>
    <Footer />
  </div>
</template>

<script>
  import SiteInfo from '@/assets/json/siteinfo.json'

  import Header from '@/components/essentials/Header.vue'
  import Main from '@/components/essentials/Main.vue'
  import Footer from '@/components/essentials/Footer.vue'

  import Hero from '@/components/common/Hero.vue'
  import Heading from '@/components/common/PageHeading.vue'
  import LeadText from '@/components/common/LeadText.vue'
  import LinkList from '@/components/common/LinkList.vue'
  import LinkBox from '@/components/common/LinkBox.vue'

  import Mail from '@/components/contact/ContactMail.vue'

  export default {
    name: 'Contact',
    components: {
      Header,
      Main,
      Footer,
      Hero,
      Heading,
      LeadText,
      LinkList,
      LinkBox,
      Mail,
    },
    data () {
      return {
        SiteInfo: SiteInfo,
        pageTitle: 'お問合わせ',
        description: 'メタモ株式会社へのお問合わせについてご案内します。',
      }
    },
    created () {
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    head: {
      title () {
        return {
          inner: this.pageTitle,
        }
      },
      meta () {
        return this.defaultHead('meta', [
          { name: 'description', content: this.description },
          { property: 'og:title', content: this.pageTitle + ' | ' + this.SiteInfo.titleShort },
          { property: 'og:description', content: this.description },
          { property: 'og:url', content: this.SiteInfo.baseURL + location.pathname },
        ])
      },
      link () {
        return this.defaultHead('link', [
          { rel: 'canonical', href: this.SiteInfo.baseURL + location.pathname },
        ])
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "@/styles/functions.scss";

  .hero {
    margin-bottom: 40px;
    background-image: url('~@/assets/images/contact/contact_hero.jpg');

    @media #{$device-l} {
      margin-bottom: 70px;
    }
  }
</style>
